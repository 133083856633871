
import {SvgFactory, SvgFactoryConfig} from './lib/svg-factory';
let config = new SvgFactoryConfig();
config.iconName = "radio-button";
config.viewBox="0 0 48 48"
config.shape = `
<g fill="var(--fill)" stroke="none" stoke-width="0">
  <circle class="outer-circle" cx="24" cy="24" r="23" stroke="var(--stroke-circle-1)" stroke-width="1" fill="var(--fill-circle-1)" />
  <circle class="inner-circle" cx="24" cy="24" r="12" stroke="var(--stroke-circle-2)" stroke-width="1" fill="var(--fill-circle-2)" fill-opacity="var(--fill-opacity-circle-2)" />
</g>
`;

let RadioButtonSVG = new SvgFactory(config);

export { RadioButtonSVG };