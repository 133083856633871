import Handlebars from 'handlebars/runtime';
import qnaDictionary from 'qna/dictionary';
import Medscape from 'medscape';
Handlebars.registerHelper('gatedLogin', function () {
    switch (Medscape.getLocale()) {
        case 'us': { return 'You must <a href=\"https://login.' + getDomain() + 'medscape.com/login/sso/getlogin\">Log In</a> to answer this question'; }
        case 'es': { return 'Debe <a href=\"https://login.' + getDomain() + 'medscape.com/login/es/sso/getlogin\">Iniciar</a> Sesión para responder a la pregunta'; }
        case 'pt': { return 'Você deve <a href=\"https://login.' + getDomain() + 'medscape.com/login/pt/sso/getlogin\">fazer log in para responder a pergunta'; }
        case 'de': { return 'Sie müssen sich <a href=\"https://login.' + getDomain() + 'medscape.com/login/de/sso/getlogin\">anmelden</a>, um die Frage zu beantworten'; }
        case 'fr': { return 'Vous devez entrer <a href=\"https://login.' + getDomain() + 'medscape.com/login/fr/sso/getlogin\">vos identifiants</a> pour répondre aux questions'; }
    }
})

Handlebars.registerHelper('getQuestionType',function(type){
    if(type==2){
        return 'Article.question.matrix'
    }
    else{
        return 'Article.question.default';
    }
});