import { BehaviorSubject } from 'rxjs';
const DefaultState = {
    choiceText: "",
    choiceId: 0,
    displayOrder: 1,
    correct: false,
    selected: false,
    explanation: null,
    totalResponses: "0",
    totalAbsoluteResponseCount: 0
}

class ChoiceState extends BehaviorSubject {
    constructor(state = Object.assign({},DefaultState)) {
        super(state);
        this._state = state;
        this._choiceId = this._state.choiceId;

    }

    get state() {
        return this._state;
    }
    get displayOrder(){
        return this.state.displayOrder;
    }
    get selected() {
        return this.state.selected;
    }
    set selected(flag = this.state.selected) {
        if (flag != this.state.selected) {
            this.state.selected = flag;
            this.next(this.state);
        }
    }
    get choiceId() {
        return this.state.choiceId;
    }
    get choiceText() {
        return this.state.choiceText;
    }
    get correct() {
        return this.state.correct;
    }
    get explanation(){
        return this.state.explanation;
    }
    get totalResponses(){
        return this.state.totalResponses;
    }
    set totalResponses(val=this.totalResponses){
        if(val!=this.totalResponses){
            this.state.totalResponses = val;
            this.next(this.state);
        }
    }
    get totalAbsoluteResponseCount(){
        return this.state.totalAbsoluteResponseCount;
    }
    set totalAbsoluteResponseCount(val = this.totalAbsoluteResponseCount){
        if(val!=this.totalAbsoluteResponseCount){
            this.state.totalAbsoluteResponseCount = val;
            this.next(this.state);
        }
    }
}

export { ChoiceState }