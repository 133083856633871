export default {
    "formId": 1,
    "formTitle": "POLL: Test Prod Supported FFQ",
    "formTypeId": 1,
    "formType": "DEFAULT",
    "displayOrder": 1,
    "introduction": "<b>Reference: 1.</b> Popoola VO, et al. <i>Thromb Res.</i> 2017;160:109-113.<br><span style='font-size:13px'>USA-123-XXXXXX</span>",
    "questions": [{
        "questionId": 385661,
        "questionText": "Here is our test supported Generic Form Check box poll question for Fast Five Quiz? ID 49192<sup>1</sup>",
        "displayType": 2,
        "poll": true,
        "required": false,
        "score": false,
        "displayOrder": 1,
        "discussion": null,
        "introduction": null,
        "choices": [{
                "choiceText": "Option 1 Here",
                "choiceId": 1212872,
                "displayOrder": 1,
                "correct": false,
                "selected": false,
                "explanation": null,
                "totalResponses": "28",
                "totalAbsoluteResponseCount": 8
            },
            {
                "choiceText": "Option 2 Here",
                "choiceId": 1212874,
                "displayOrder": 2,
                "correct": false,
                "selected": false,
                "explanation": null,
                "totalResponses": "14",
                "totalAbsoluteResponseCount": 4
            },
            {
                "choiceText": "Option 3 Here",
                "choiceId": 1212876,
                "displayOrder": 3,
                "correct": false,
                "selected": false,
                "explanation": null,
                "totalResponses": "27",
                "totalAbsoluteResponseCount": 8
            },
            {
                "choiceText": "Option 4 Here",
                "choiceId": 1212878,
                "displayOrder": 4,
                "correct": true,
                "selected": false,
                "explanation": null,
                "totalResponses": "10",
                "totalAbsoluteResponseCount": 3
            },
            {
                "choiceText": "Undecided",
                "choiceId": 1212880,
                "displayOrder": 5,
                "correct": false,
                "selected": false,
                "explanation": null,
                "totalResponses": "21",
                "totalAbsoluteResponseCount": 6
            }
        ],
        "showAnsTable": true,
        "showQuestion": true,
        "showResult": true,
        "responseText": null,
        "answered": false,
        "answeredCorrectly": false,
        "questionTypeId": 1,
        "mutuallyExclusive": false,
        "matrixQuestions": [],
        "totalResponses": 29,
        "viewResults": false,
        "specialties": [],
        "professions": []
    }],
    "isActive": 1,
    "gated": true,
    "expirationDate": null,
    "viewResults": false
}