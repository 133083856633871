import { QuestionnaireComponent } from 'qna/core/questionnaire/questionnaire-component';
import { FormView } from './form-view';
import Medscape from 'medscape'
import { RENDER_STATES } from 'qna/core/render-component';
export default class SponsoredPoll extends QuestionnaireComponent {
    constructor(props, containerEl) {
        super(containerEl);
        this._props = props;
        this._formView = null
        this._onSubmitSource = null;
    }
    get props(){
        return this._props;
    }
    get type(){
        return "SPONSORED_POLL";
    }
    get formView() {
        if (this._formView == null) {
            this._formView = new FormView();
        }
        return this._formView;
    }

    set formView(formView) {
        this._formView = formView;
    }

    get onSubmitSource(){
        return this._onSubmitSource;
    }
    set onSubmitSource(source){
        this._onSubmitSource = source;
    }

    init() {
        super.init();
        this.el.classList.add("sponsored-poll");
        let formId = this.el.dataset.formid;
        
        return this.api.getFormData(formId).then(formState=>{
            let formId = formState.formId;
            this.formView = new FormView(formState);
            this.formView.api.service = this.api.service;
            this.formView.feedbackMessage = this.props.config.feedbackMessage;
            this.el.appendChild(this.formView.el);
            this.formView.render();
            this.formView.init();
            this.formView.renderObserver.subscribe(state=>{
                if(state.type==RENDER_STATES.BEFORE){
                    this.handleBeforeRender();
                }
                else if(state.type==RENDER_STATES.AFTER){
                    this.handleAfterRender();
                } 
            })
            return formState;
        })
    }
    handleBeforeRender(){
        if(this.onSubmitSource != null){
            this.onSubmitSource.unsubscribe();
            this.onSubmitSource = null;
        }
    }
    handleAfterRender(){
        if (this.formView.onSubmit != null) {
            this.onSubmitSource = this.formView.onSubmit.subscribe(data => {
                let { formState } = data;
                if (this.formView.api.isValidForm) {
                    this.api.submitForm(formState).then(data=>{
                        console.log("Poll Submitted:",data);
                        this.formView.isSubmitted = true;
                        this.formView.api.showResultsView();
                    },
                    error=>{
                        console.error("Submit Poll Error:",error);
                        this.formView.api.setError(error);
                    });
                } else {
                    console.log("Form is inValid");
                }
            })
        }
    }

}

