
import {SvgFactory, SvgFactoryConfig} from './lib/svg-factory';
let config = new SvgFactoryConfig();
config.iconName = "ffq-wrong";
config.viewBox="0 0 25 26"
config.shape = `
<circle fill="var(--fill-circle)" cx="12.5" cy="12.5" r="12.5"></circle>
<path fill="var(--fill-path)" d="M14.4311124,12.5 L17.4781452,15.4424853 L15.6144276,17.3724214 L12.5,14.3648536 L9.38557241,17.3724214 L7.52185483,15.4424853 L10.5688876,12.5 L7.52185483,9.55751469 L9.38557241,7.62757864 L12.5,10.6351464 L15.6144276,7.62757864 L17.4781452,9.55751469 L14.4311124,12.5 Z"></path>
`;

let SVG = new SvgFactory(config);

export { SVG };
