import { FormApi } from 'qna/core/questionnaire/form/form-api';

class ArticleFormApi extends FormApi{
	constructor(state){
		super(state);
	}
	get isValidForm(){
		let isValidForm = true;
		const isInstantPoll = this.state.formTypeId === 16;
		if(this.state.formTypeId === 16){
			let questions = this.state.questions;
			questions.forEach(question=>{
				if(!question.answered){
					isValidForm = false;
				}
			})	
		}
		else{
			isValidForm = super.isValidForm;
		}
		return isValidForm;
	}
}

export { ArticleFormApi }