import Handlebars from 'handlebars/runtime';
import qnaDictionary from 'qna/dictionary';
import Medscape from 'medscape';
import { CorrectCheckmark, WrongX } from 'svgs';
import { RadioButtonSVG } from 'svgs/radio-button';
import { SVG as ffqCorrectSVG } from 'svgs/ffq-correct';
import { SVG as ffqWrongSVG } from 'svgs/ffq-wrong';

//let Handlebars = window.Handlebars;
Handlebars.registerHelper('if_eq', function(a, b, opts) {
    if (a == b) {
        return opts.fn(this);
    }
});

Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {

    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '!=':
            return (v1 != v2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
});

Handlebars.registerHelper('counter', function (index) {
    this.counter = Number(index)+1;
    return this.counter;
});

Handlebars.registerHelper('var', function (name, value) {
    this[name] = value;
});

Handlebars.registerHelper('set', function (name, value) {
    var temp = 0;
    for (var i = 0; i < value.length ; i++) {
        if (value[i].correct) {
            temp = temp + 1;
        }
    }
    this[name] = temp;
});

Handlebars.registerHelper('locale', function (name, id) {
    if (id >= 0) {
        return qnaDictionary[name][Medscape.getLocale()][id];
    }
    
    return qnaDictionary[name][Medscape.getLocale()];
});



Handlebars.registerHelper('renderPartial', function(partialName, options) {
    return partialName;
});

Handlebars.registerHelper('renderSVG', function(name) {
    let svg = "";
    switch(name){
        case 'correct-checkmark':{
            svg = CorrectCheckmark;
            break;
        }
        case 'wrong-x':{
            svg = WrongX;
            break
        }
        case 'radio-button':{
            svg = RadioButtonSVG.useSpriteString;
            break;
        }
        case 'ffq-correct':{
            svg = ffqCorrectSVG.useSpriteString;
            break;
        }
        case 'ffq-wrong':{
            svg = ffqWrongSVG.useSpriteString;
            break;
        }
    }
    return svg;
});
