import { FormApi } from './form-api';
import { QuestionComponent } from './question/question-component';
import { SubmitButtonComponent } from './submit-button/submit-button-component';
import { FiltersComponent } from './filters/filters-component';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { RenderComponent, RENDER_STATES } from 'qna/core/render-component';
class FormComponent extends RenderComponent {
    constructor(formState=null) {
        super();
        this._el = null;
        this._id = null;
        this._questions = null;
        this._api = formState!=null ? new this.FormApi(formState) : null;
        this._onSubmit = null;
        this._submitButton = null;
        this._renderSubscriptions = [];
        this._viewResultsEls = null;
        this._onViewResultsClick = null;
        this._showResultsState = formState.showResultsState;
        this._filtersView = null;
    }
    get api() {
        if (this._api == null) {
            this._api = new this.FormApi();
        }
        return this._api;
    }
    get FormApi(){
        return FormApi;
    }
    get state() {
        return this.api.state;
    }
    get showResultsViewState() {
        return this._showResultsState;
    }
    set showResultsViewState(flag) {
        this._showResultsState = flag;
    }
    get el() {
        if (this._el == null) {
            this._el = document.createElement('div');
            this._el.classList.add('form-component');
        }
        return this._el;
    }
    get id() {
        if (this._id == null) {
            this._id = this.el.dataset.formId || this.state.formId;
        }
        return this._id;
    }
    get QuestionView() {
        return QuestionComponent;
    }
    get questions() {
        if (this._questions == null) {
            let QuestionView = this.QuestionView;
            let questions = [...this.el.querySelectorAll('.question-component')];
            this._questions = questions.map(questionEl => {
                let questionId = questionEl.dataset.questionId;
                let questionState = this.api.getQuestionState(questionId);
                let questionView = new QuestionView(questionEl, questionState);
                questionView.init();
                return questionView;
            })
        }
        return this._questions;
    }
    set questions(questions = null) {
        this._questions = questions;
    }
    get filtersView(){
        if(this._filtersView == null){
            let filtersEl = this.el.querySelector('.filters-component');
            if(filtersEl!=null){
                this._filtersView = new FiltersComponent(filtersEl);
                this._filtersView.init();
            }
        }
        return this._filtersView;
    }
    set filtersView(view=null){
        this._filtersView = view;
    }
    get renderSubscriptions() {
        return this._renderSubscriptions;
    }
    set renderSubscriptions(subscriptions = []) {
        this._renderSubscriptions = subscriptions;
    }
    get RadioButtonSVG() {
        return RadioButtonSVG;
    }
    get handlebarData() {
        let handlebarData = this.state.toJSON();
        $.extend(true, handlebarData, this.config); // PPE-330446 Change Button Text to 'Submit' on All Editions
        handlebarData.questions.forEach(question => {
            let correctChoice = question.choices.find(choice => {
                return choice.correct == true;
            })
            question.hasCorrectChoice = correctChoice != null;
        })

        return handlebarData;
    }
    get template() {
        return '';
    }
    get SubmitButtonComponent(){
        return SubmitButtonComponent;
    }
    get submitButton() {
        if (this._submitButton == null) {
            let submitButtonEl = this.el.querySelector(".submit-button-component");
            if (submitButtonEl != null) {
                this._submitButton = new this.SubmitButtonComponent(submitButtonEl);
            }
        }
        return this._submitButton;
    }
    set submitButton(submitButton) {
        this._submitButton = submitButton;
    }
    get viewResultsEls() {
        return [...this.el.querySelectorAll('.view-results')];
    }

    get returnToQuestionEls() {
        return [...this.el.querySelectorAll('.return-to-question')]
    }

    get onSubmit() {
        if (this._onSubmit == null) {
            this._onSubmit = new Subject();
        }
        return this._onSubmit;
    }
    get formEl() {
        let formEl = this.el.tagName == "FORM" ? this.el : this.el.querySelector('form');
        return formEl;
    }
    get errorEls() {
        let errorEls = this.el.querySelector('.error-message') ? this.el.querySelectorAll('.error-message') : this.el.querySelectorAll('.errorMsg');
        errorEls = [...errorEls]
        return errorEls;
    }
    get service(){
        return this.api.service;
    }
    init() {
        super.init();
        this.state.pipe(distinctUntilChanged(null, state => {
            return state.showResultsView;
        })).subscribe(state => {
            this.handleViewChange(state.showResultsView);
        })

        this.state.pipe(distinctUntilChanged(null, state => {
            return state.errorMessage;
        })).subscribe(state => {
            this.handleErrorMessageChange(state.errorMessage);
        })
    }
    handleViewChange(showResultsView = this.state.showResultsView) {
        if (this.showResultsViewState != showResultsView) {
            this.questions.forEach(question => {
                question.destroy();
            })
            this.questions = null;
            this.render();
        }
    }
    handleErrorMessageChange(errorMsg="") {
        this.errorEls.forEach(el => {
            el.innerHTML = errorMsg;
        })
    }
    handleBeforeRender() {
        if (this.submitButton != null) {
            this.submitButton.destroy();
            this.submitButton = null;
        }

        this.renderSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
        this.renderSubscriptions = [];
        this.questions.forEach(question => {
            question.beforeRender();
        })
        this.questions = null;
        if (this.formEl != null) {
            this.formEl.removeEventListener('submit', this.handleFormSubmit);
        }

        if(this.filtersView!=null){
            this.filtersView.beforeRender();
            this.filtersView.destroy();
            this.filtersView=null;
        }
        
    }

    handleAfterRender() {
        this.showResultsViewState = this.state.showResultsView;
        if (this.state.showResultsView == false) {
            if (this.submitButton != null) {
                this.submitButton.init();
                this.state.questions.forEach(questionState => {
                    let subscription = questionState.pipe(distinctUntilChanged(null, state => {
                            return state.answered;
                        }))
                        .subscribe(questionState => {
                            if (this.api.isValidForm) {
                                this.submitButton.api.activate();
                            } else {
                                this.submitButton.api.disable();
                            }
                        })
                    this.renderSubscriptions.push(subscription);
                });
            }
            this.viewResultsEls.forEach(el => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.api.showResultsView();
                })
            })

            if (this.formEl != null) {
                this.formEl.addEventListener('submit', this.handleFormSubmit.bind(this));
            }

        } else {
            this.returnToQuestionEls.forEach(el => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.api.showQuestionsView();
                })
            })
        }
        this.questions.forEach(question => {
            question.afterRender();
        });
        if(this.filtersView!=null){
            this.filtersView.afterRender();
            let filtersSubscription = this.filtersView.state.subscribe(state=>{

                this.handleFilterStateChange(state);
            });
            this.renderSubscriptions.push(filtersSubscription);
        }


    }
    handleFilterStateChange(filterState){
        this.service.getFilterData(this.id,filterState).then(filterResponseData=>{
            filterResponseData.questionResponseSummaries.forEach(questionSummary=>{
                const questionId = questionSummary.questionId;
                const questionView = this.questions.find(question=>{
                    return question.id == questionId;
                })
                if(questionView!=null){
                    questionView.api.updateFilterChoices(questionSummary);
                }
            })
        })
    }
    handleFormSubmit(e) {
        this.onSubmit.next({
            event: e,
            formState: this.state
        })
    }

    render() {
        this.beforeRender();
        let handlebarData = this.handlebarData;
        let html = this.template(handlebarData);
        this.el.innerHTML = html;
        this.afterRender();
    }

}

export { FormComponent }