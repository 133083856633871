import { QuestionnaireApi } from './questionnaire-api';
import { QuestionnaireState } from './questionnaire-state';


class QuestionnaireComponent{
	constructor(el){
		this._el = el;
		this._id = null;
		this._forms = [];
		this._api = null;
	}
	get api(){
		if(this._api == null){
			this._api = new QuestionnaireApi(this.id);
		}
		return this._api;
	}
	get state(){
		return this.api.state;
	}
	get el(){
		return this._el;
	}
	get id(){
		if(this._id==null){
			this._id = this.el.dataset.questionnaireid;
		}
		return this._id;
	}
	get forms(){
		return this._forms;
	}
	get state(){
		return this.api.state;
	}
	init(){
		let promise = new Promise( (resolve,reject)=>{
			this.el.classList.add('questionnaire-component');
			let questionnaireId = this.el.dataset.questionnaireid;
			let formId = this.el.dataset.formid;
			this.api.setId(questionnaireId);
			resolve();
		});
		return promise;
	}

	render(){

	}

}

export { QuestionnaireComponent }