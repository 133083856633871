import { Subject } from 'rxjs';
import { RenderComponent, RENDER_STATES } from 'qna/core/render-component';
class FilterItemComponent extends RenderComponent{

    constructor(el){
        super();
        this._el = el;
        this._onChange = null;
    }

    get el(){
        return this._el;
    }
    get key(){
        return this.el.dataset.key;
    }
    get selectEl(){
        return this.el.querySelector('select');
    }
    get onChange(){
        if(this._onChange==null){
            this._onChange = new Subject();
        }
        return this._onChange;
    }

    onSelectChange(e){
        let state = {
            event: e,
            key: this.key,
            id: e.target.value
        }
        this.onChange.next(state);
    }
    handleBeforeRender() {
        if(this.selectEl!=null){
            this.selectEl.removeEventListener('change',this.onSelectChange)
        }
    }
    handleAfterRender() {
        if(this.selectEl!=null){
            this.selectEl.addEventListener('change',this.onSelectChange.bind(this))
        }
    }
}

export { FilterItemComponent }