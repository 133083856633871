import { QuestionApi } from './question-api';
import { ChoiceComponent } from './choice/choice-component';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { RenderComponent, RENDER_STATES } from 'qna/core/render-component';
import { DISPLAY_TYPES } from './display-types';
let doLog = false;
class QuestionComponent extends RenderComponent {
    constructor(el, questionState) {
        super();
        this._el = el;
        this._choices = null;
        this._api = new QuestionApi(questionState);
        this._onResponseTextKeyUp = null;
        this._onSelectChange = null;
        this._matrixQuestions = null;
        this._stateSubscriptions = [];
        this._initSubscriptions = [];
    }
    get id() {
        return this.el.dataset.questionId || this.state.questionId;
    }
    get api() {
        return this._api;
    }
    get state() {
        return this.api.state;
    }
    get el() {
        return this._el;
    }
    set el(el) {
        this._el = el;
    }
    get ChoiceView() {
        return ChoiceComponent;
    }
    get MatrixQuestionView() {
        return QuestionComponent;
    }
    get initSubscriptions() {
        return this._initSubscriptions;
    }

    get choices() {
        if (this._choices == null) {
            let choices = [...this.el.querySelectorAll('.choice-component')];
            let ChoiceView = this.ChoiceView;
            this._choices = choices.map(choiceEl => {
                let choiceId = choiceEl.dataset.choiceId;
                let choiceState = this.state.choices.find(choice => {
                    return choice.choiceId == choiceId;
                });
                if (choiceState != null) {
                    return new ChoiceView(choiceEl, choiceState);
                }
            });

        }
        return this._choices;
    }
    set choices(choices = null) {
        this._choices = choices;
    }
    get matrixQuestions() {
        if (this._matrixQuestions == null) {
            let questions = [...this.el.querySelectorAll('.matrix-question-component')];

            let QuestionView = this.MatrixQuestionView;
            this._matrixQuestions = questions.map(questionEl => {

                let questionId = questionEl.dataset.questionId;
                let questionState = this.state.matrixQuestions.find(question => {
                    return question.questionId == questionId;
                });

                if (questionState != null) {
                    return new this.MatrixQuestionView(questionEl, questionState);
                }
            });
        }
        return this._matrixQuestions;
    }
    get responseTextEl() {
        if (this.state.displayType == DISPLAY_TYPES['TEXT']) {
            return this.el.querySelector('input[type="text"]');
        } else if (this.state.displayType == DISPLAY_TYPES['TEXTAREA']) {
            return this.el.querySelector('textarea');
        }
        return null;
    }
    get onResponseTextKeyUp() {
        if (this._onResponseTextKeyUp == null) {
            this._onResponseTextKeyUp = new Subject();
        }
        return this._onResponseTextKeyUp;
    }
    get onSelectChange() {
        if (this._onSelectChange == null) {
            this._onSelectChange = new Subject();
        }
        return this._onSelectChange;
    }

    get selectEl() {
        return this.el.querySelector('select');
    }

    init() {
        super.init();
        if (this.state.matrixQuestions != null && this.state.matrixQuestions.length > 0) {
            doLog = true;
            this.matrixQuestions.forEach(question => {
                question.init();
            })
            doLog = false;
        } else {

            if (this.state.displayType == DISPLAY_TYPES['RADIO'] || this.state.displayType == DISPLAY_TYPES['CHECKBOX']) {
                this.choices.forEach(choice => {
                    choice.init();
                })
            } else if (this.state.displayType == DISPLAY_TYPES['TEXT'] || this.state.displayType == DISPLAY_TYPES['TEXTAREA']) {
                let subscription = this.onResponseTextKeyUp
                    .pipe(debounceTime(250))
                    .subscribe(state => {
                        this.api.updateResponseText(state.value);
                    })
                this.initSubscriptions.push(subscription);

            } else if (this.state.displayType == DISPLAY_TYPES['SELECT']) {
                let subscription = this.onSelectChange.subscribe(state => {
                    this.api.updateSelectChoice(state.value);
                })
                this.initSubscriptions.push(subscription);
            }

            if (this.state.displayType == DISPLAY_TYPES['TEXT'] || this.state.displayType == DISPLAY_TYPES['TEXTAREA']) {
                let subscription = this.state.pipe(
                    distinctUntilChanged(null, state => {
                        return state.responseText;
                    })
                ).subscribe(state => {
                    this.api.checkAnswered();
                });
                this.initSubscriptions.push(subscription);

            } else {
                this.state.choices.forEach(choiceState => {
                    let subscription = choiceState
                        .pipe(distinctUntilChanged(null, state => {
                            return state.selected;
                        }))
                        .subscribe(choiceState => {
                            this.handleChoiceStateSelectedChange(choiceState);
                        })
                    this.initSubscriptions.push(subscription);
                })
            }
        }
    }
    destroy() {
        super.destroy();
        this.choices.forEach(choice => {
            choice.destroy();
        })
        this.choices = null;
        this.initSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
        this.el = null;
    }

    handleResponseTextKeyup(e) {
        let state = {
            event: e,
            value: e.target.value
        }
        this.onResponseTextKeyUp.next(state)
    }
    handleSelectChange(e) {
        let state = {
            event: e,
            value: e.target.value
        }
        this.onSelectChange.next(state);
    }
    handleChoiceStateSelectedChange(choiceState) {
        this.api.updateChoices(choiceState);
        this.api.checkAnswered();
    }
    handleBeforeRender() {
        this.choices.forEach(choice => {
            choice.beforeRender();
        })
        if(this.matrixQuestions!=null && this.matrixQuestions.length>0){
            this.matrixQuestions.forEach(question=>{
                question.beforeRender();
            });
        }
        if (this.responseTextEl != null) {
            this.responseTextEl.removeEventListener('keyup', this.handleResponseTextKeyup)
        }

    }
    handleAfterRender() {
        this.choices.forEach(choice => {
            choice.afterRender();
        })
        if(this.matrixQuestions!=null && this.matrixQuestions.length>0){
            this.matrixQuestions.forEach(question=>{
                question.afterRender();
            });
        }
        if (this.responseTextEl != null) {
            this.responseTextEl.addEventListener('keyup', this.handleResponseTextKeyup.bind(this))
        } else if (this.selectEl != null) {
            this.selectEl.addEventListener('change', this.handleSelectChange.bind(this))
        }
    }

}

export { QuestionComponent }