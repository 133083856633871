const config = {
    showViewResult: true,
    showPollFilter: true,
    showTotalResponse: true,
    showReturnToQuestion: true,
    showFeedback: false,
    feedbackMessage: 'Thank you for your feedback!',
    showSubmitIndicator: false
}

export default config;