import { QuestionComponent } from 'qna/core/questionnaire/form/question/question-component';
import { ChoiceComponent } from 'qna/core/questionnaire/form/question/choice/choice-component';

const TRANSITION_END_EVENTS = "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend";
/* Override default Component to handle animations */
class ResultsTemplateQuestionView extends QuestionComponent{
	constructor(el,questionState){
		super(el,questionState);

	}
	get ChoiceView(){
		return ChoiceView;
	}

	init(){
		super.init();

	}



}


class ChoiceView extends ChoiceComponent{
	constructor(el,state){
		super(el,state);
	}
	get answerPercentEl(){
		return this.el.querySelector('.answer-percent');
	}
	get answerBarEl(){
		return this.el.querySelector('.answer-bar');
	}
    handleTotalResponsesChange(totalResponses){
    	if(this.answerPercentEl!=null){
    		this.answerPercentEl.innerHTML = `${totalResponses}%`;
    	}
    	if(this.answerBarEl!=null){
    		this.answerBarEl.style.width=`${totalResponses}%`;
    	}

    }
	handleBeforeRender(){
		super.handleBeforeRender();
		this.el.classList.remove('ready');
	}
	handleAfterRender(){
		super.handleAfterRender();
        setTimeout(()=>{
        	this.el.classList.add('ready');
        },500)
	}
}

export { ResultsTemplateQuestionView }