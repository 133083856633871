import { BehaviorSubject, Subject } from 'rxjs';

const DefaultState = {
	width: "0%"
}
class ResponseBarState extends BehaviorSubject{
	constructor(state = Object.assign({},DefaultState)){
		super(state);
		this._state = state;
	}
	get state(){
		return this._state;
	}
	get width(){
		return this.state.width;
	}
	set width(val=this.state.width){
		if(val!=this.width){
			this.state.width = val;
			this.next(this.state);
		}
	}
}

class ResponseBarApi{
	constructor(state=null){
		this._state = state;
	}
	get state(){
		if(this._state==null){
			this._state = new ResponseBarState();
		}
		return this._state;
	}

	width(val){
		this.state.width = val;
	}
}

class ResponseBarComponent{
	constructor(el,api=null){
		this._el = el;
		this._api = api;
		this._ResultsBarEl = null;
		this._onTransitionEnd = null;
		this.subscriptions = [];
	}
	get el(){
		return this._el;
	}
	set el(el){
		this._el = el;
	}
	get api(){
		if(this._api==null){
			this._api = new ResponseBarApi();
		}
		return this._api;
	}
	get state(){
		return this.api.state;
	}
	get onTransitionEnd(){
		if(this._onTransitionEnd==null){			
			this._onTransitionEnd = new Subject();
		}
		return this._onTransitionEnd;
	}
	get ResultsBarEl(){
		if(this._ResultsBarEl==null){
			this._ResultsBarEl=document.createElement('div');
			this._ResultsBarEl.classList.add("results-bar");
		}
		return this._ResultsBarEl;
	}
	destroy(){
		this.subscriptions.forEach( subscription=>{
			subscription.unsubscribe();
		})
		this.subscriptions = [];
		this.el = null;
	}
	init(){
		let subscription = this.state.subscribe(barState=>{
			this.updateWidth(barState.width);
		})
		this.subscriptions.push(subscription);
		this.el.appendChild(this.ResultsBarEl);
		this.el.addEventListener('transitionend',(e)=>{
			this.onTransitionEnd.next(e)
		})
	}
	updateWidth(val=0){
		this.ResultsBarEl.style.width = val;
	}
}

export { ResponseBarComponent }
export { ResponseBarApi }
export { ResponseBarState }