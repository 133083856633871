import { FormComponent } from 'qna/core/questionnaire/form/form-component';
import { default as QuestionTemplate } from 'templates/article.questions';
import { default as ResultTemplate } from 'templates/article.results';
import { ResultsTemplateQuestionView } from './results-template-question-view';
import { QuestionsTemplateQuestionView } from './questions-template-question-view';
import { SubmitButton } from './submit-button';
import { ArticleFormApi } from './article-form-api';
import { FILTER_KEYS } from 'qna/core/questionnaire/form/filters/filter-keys';
import Medscape from 'medscape';
import config from './config';
import { distinctUntilChanged } from 'rxjs/operators';

class FormView extends FormComponent {
    constructor(formState) {
        super(formState);
        this._config = config;
        this._isSubmitted = false;
    }
    get config() {
        return this._config;
    }
    set config(config) {
        this._config = config;
    }
    get template() {
        if (this.state.showResultsView == true) {
            return ResultTemplate;
        } else {
            return QuestionTemplate;
        }
    }
    get FormApi(){
        return ArticleFormApi;
    }
    get QuestionView() {
        if (this.state.showResultsView == true) {
            return ResultsTemplateQuestionView;
        } else {
            return QuestionsTemplateQuestionView;
        }
    }
    get feedbackMessage() {
        return this._feedbackMessage;
    }
    set feedbackMessage(message = null) {
        this._feedbackMessage = message;
    }
    get totalResponsesEl() {
        return this.el.querySelector('#qnaTotalResponses');
    }
    get isSubmitted() {
        return this._isSubmitted;
    }
    set isSubmitted(flag = this.isSubmitted) {
        this._isSubmitted = flag;
    }
    get SubmitButtonComponent(){
        return SubmitButton;
    }
    get handlebarFiltersData() {
        let professionMap = new Map();
        let specialtiesMap = new Map();

        this.state.questions.forEach(question => {
            question.professions.forEach(profession => {
                const { professionId, description } = profession;
                professionMap.set(professionId, description);
            })
            question.specialties.forEach(specialty => {
                const { specialtyId, description } = specialty;
                specialtiesMap.set(specialtyId, description);
            })
        })

        let professions = Array.from(professionMap).map(item => {
            return {
                id: item[0],
                description: item[1]
            }
        });
        let specialties = Array.from(specialtiesMap).map(item => {
            return {
                id: item[0],
                description: item[1]
            }
        });


        let profession = {
            titleLocaleKey: "filterByProfession",
            localeName: FILTER_KEYS["PROFESSION"],
            name: FILTER_KEYS["PROFESSION"],
            items: professions
        }
        let specialty = {
            titleLocaleKey: "filterBySpecialty",
            localeName: FILTER_KEYS["SPECIALTY"],
            name: FILTER_KEYS["SPECIALTY"],
            items: specialties
        }
        let filters = [specialty, profession];
        return filters;
    }
    get handlebarData() {
        let handlebarData = super.handlebarData;
        if (this.state.showResultsView) {
            handlebarData = this.getResultsTemplateHandlebarData(handlebarData);
        } else {
            handlebarData = this.getQuestionsTemplateHandlebarData(handlebarData);
        }
        handlebarData.gated =  handlebarData.gated && !Medscape.User.isLoggedIn() && !Medscape.ArticlePage.isPrintPage();
        handlebarData.expired = this.api.expired;
        return handlebarData;
    }
    getQuestionsTemplateHandlebarData(handlebarData = {}) {
        if (handlebarData.formTypeId == 16 && this.config.showViewResult == true) {
            handlebarData.viewResults = true;
        }
        return handlebarData;
    }
    getResultsTemplateHandlebarData(handlebarData = {}) {
        let config = this.config;
        const isInstantPoll = handlebarData.formTypeId === 16;
        let unanswered = handlebarData.questions.find((question) => {
            return question.answered == false;
        })
        const hasUnanswered = unanswered != null;
        handlebarData.isSubmitted = this.isSubmitted;

        if (!this.isSubmitted) {
            handlebarData.questions.forEach(question => {
                question.choices.forEach(choice => {
                    choice.selected = false;
                })
            })
        }
        let filter = handlebarData.questions.find(question => {
            let hasProfessions = question.professions != null && question.professions.length > 0;
            let hasSpecialties = question.specialties != null && question.specialties.length > 0;
            return hasProfessions || hasSpecialties;
        })
        let customData = {
            feedback: config.showFeedback,
            message: config.feedbackMessage,
            filters: isInstantPoll ? this.handlebarFiltersData : [],
            applyFilter: isInstantPoll && config.showPollFilter && filter != null,
            totalResponses: isInstantPoll && config.showTotalResponse,
            returnToQuestion: isInstantPoll && config.showReturnToQuestion && !Medscape.ArticlePage.isPrintPage() && !this.state.isSubmitted
        }
        handlebarData = Object.assign(handlebarData, customData);

        const questionsLength = handlebarData.questions.length;
        handlebarData.questions.forEach((question, index) => {

            question.showHeader = (questionsLength > 1 && question.showAnsTable);
            question.index = index + 1;
            question.questionsLength = questionsLength;
            let answered = question.answered;
            if (question.displayType == 1 || question.displayType == 2) {
                let correctChoice = question.choices.find(choice => {
                    return choice.correct;
                })
                let hasCorrectChoice = correctChoice != null;
                question.choices.forEach(choice => {
                    let labelClasses = [];
                    if (!answered && !this.isSubmitted) {
                        choice.selected = false;
                    }
                    if (hasCorrectChoice) {
                        if (choice.correct) {
                            labelClasses.push("answer-correct");
                            if (choice.selected) {
                                labelClasses.push("answer-survey");
                            }
                        } else if (!choice.correct && choice.selected) {
                            labelClasses.push("answer-wrong");
                        }

                    } else if (choice.selected) {
                        labelClasses.push("answer-survey")
                    }
                    choice.labelClasses = labelClasses.join(" ");
                });

            }
        });
        return handlebarData;
    }
    init() {
        super.init();
        this.questions.forEach(questionView => {
            questionView.state.pipe(
                    distinctUntilChanged(null, state => {
                        return state.totalResponses;
                    }))
                .subscribe(state => {
                    this.handleQuestionTotalResponsesChange(state.totalResponses);
                })
        })

    }
    handleQuestionTotalResponsesChange(totalResponses = 0) {
        if (this.totalResponsesEl != null) {
            this.totalResponsesEl.innerHTML = `${totalResponses}`;
        }
    }
    handleShowResultsChange(showResults = this.state.showResults) {
        super.handleShowResultsChange(showResults);
    }

    handleBeforeRender() {
        super.handleBeforeRender();

    }
    handleAfterRender() {
        super.handleAfterRender();

    }
}
export { FormView }