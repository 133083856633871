import Handlebars from "handlebars/runtime";export default Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"FastFiveArticle.question"),depth0,{"name":"FastFiveArticle.question","hash":{"questionsLength":((stack1 = (depths[1] != null ? lookupProperty(depths[1],"questions") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-introduction\">\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"introduction") || (depth0 != null ? lookupProperty(depth0,"introduction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"introduction","hash":{},"data":data,"loc":{"start":{"line":17,"column":19},"end":{"line":17,"column":37}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"questions-template form-view\" data-form-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":67}}}) : helper)))
    + "\" action=\"javascript:void(0);\">\n    <h3>"
    + ((stack1 = (lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":31}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"displayOrder") || (depth0 != null ? lookupProperty(depth0,"displayOrder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayOrder","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":50}}}) : helper)))
    + "<span class=\"count-total\">/5</span></h3>\n    <div class=\"white-box-container\">\n        <div class=\"questions-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"questions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"form-actions\">\n            <button class=\"submit-button-component "
    + alias4(((helper = (helper = lookupProperty(helpers,"allowSubmitClass") || (depth0 != null ? lookupProperty(depth0,"allowSubmitClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"allowSubmitClass","hash":{},"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":71}}}) : helper)))
    + "\" type=\"submit\">\n                "
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"submitAndProceed",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":45}}}))
    + "\n            </button>\n        </div>\n        <div class=\"errorMsg\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</form>";
},"usePartial":true,"useData":true,"useDepths":true});