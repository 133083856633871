import { SubmitButtonComponent } from 'qna/core/questionnaire/form/submit-button/submit-button-component';

class SubmitButton extends SubmitButtonComponent{
	constructor(el){
		super(el);
	}
	update(isActive=this.state.isActive){
        let buttonClassList = this.el.classList;
        if(isActive){
            buttonClassList.remove('qna-submit-invalid')
        }
        else{
            buttonClassList.add('qna-submit-invalid');
        }
	}
}

export { SubmitButton }