import { BehaviorSubject } from 'rxjs';
const RENDER_STATES = {
	"AFTER": "AFTER",
	"BEFORE": "BEFORE"
}
class RenderComponent{
	constructor(){
        this._renderObserver = null;
        this._renderSource = null;
	}
    init(){
        this.renderSource = this.renderObserver.subscribe((state) => {
            if (state.type == RENDER_STATES.BEFORE) {
                this.handleBeforeRender();
            } else if (state.type == RENDER_STATES.AFTER) {
                this.handleAfterRender();
            }
        })
    }
    destroy(){
        if(this.renderSource!=null){
            this.renderSource.unsubscribe();
        }
    }

    get renderObserver() {
        if (this._renderObserver == null) {
            this._renderObserver = new BehaviorSubject({
                type: ""
            })
        }

        return this._renderObserver;
    }

    get renderSource(){
    	return this._renderSource;
    }
    set renderSource(source){
    	this._renderSource = source;
    }

    beforeRender(){
        this.renderObserver.next({
            type: RENDER_STATES.BEFORE
        });
    }
    afterRender(){
        this.renderObserver.next({
            type: RENDER_STATES.AFTER
        });
    }
    handleBeforeRender(){};
    handleAfterRender(){}
}

export { RENDER_STATES }
export { RenderComponent}