import { ChoiceApi } from './choice-api';
import { ResponseBarComponent } from './response-bar';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { RenderComponent, RENDER_STATES } from 'qna/core/render-component';
class ChoiceComponent extends RenderComponent{
    constructor(el, choiceState) {
        super();
        this._el = el;
        this._choices = null;
        this._api = new ChoiceApi(choiceState);
        this._onChange = null;
        this._ResponseBar = null;
        this._subscriptions = [];
        this._initSubscriptions = [];
    }
    get api() {
        return this._api;
    }
    get el() {
        return this._el;
    }
    set el(el){
        this._el = el;
    }
    get state() {
        return this.api.state;
    }
    get initSubscriptions(){
        return this._initSubscriptions;
    }
    get subscriptions(){
        return this._subscriptions;
    }
    set subscriptions(subscriptions=[]){
        this._subscriptions = subscriptions;
    }
    get ResponseBarView(){
        return ResponseBarComponent;
    }
    get ResponseBar(){
        if(this._ResponseBar==null){
            let responseBar = this.el.querySelector('.response-bar-component');
            if(responseBar!=null){
                this._ResponseBar = new this.ResponseBarView(responseBar)
            }
        }
        return this._ResponseBar;
    }
    set ResponseBar(ResponseBar){
        this._ResponseBar = ResponseBar;
    }
    get onChange() {
        if (this._onChange == null) {
            this._onChange = new Subject();
        }
        return this._onChange;
    }
    get inputEl(){
        return this.el.querySelector('input[type="radio"],input[type="checkbox"]');
    }
    init() {
        super.init();
        let subscription = null;
        subscription = this.state
            .pipe(distinctUntilChanged(null, state => {
                return state.selected;
            }))
            .subscribe(choiceState => {
                this.updateSelected(choiceState.selected);
            })
        this.initSubscriptions.push(subscription);
        
        subscription = this.state
            .pipe(distinctUntilChanged(null, state => {
                return state.totalResponses
            }))
            .subscribe(choiceState => {
                this.handleTotalResponsesChange(choiceState.totalResponses);
            })
        this.initSubscriptions.push(subscription);
    }
    destroy(){
        super.destroy();
        this.initSubscriptions.forEach(subscription=>{
            subscription.unsubscribe();
        })
        this.handleBeforeRender();
        this.el = null;

    }
    updateSelected(isSelected=this.state.selected){
    	if(isSelected){
    		this.el.classList.add("selected");
    	}
    	else{
    		this.el.classList.remove("selected");
    	}
    }
    handleTotalResponsesChange(totalResponses){
    }
    handleInputChange(e){
        this.onChange.next(e);

    }
    handleBeforeRender(){
        this.subscriptions.forEach(subscription=>{
            subscription.unsubscribe();
        })
        this.subscriptions = [];
        if(this.ResponseBar!=null){
            this.ResponseBar.destroy();
            this.ResponseBar = null;
        }

        if(this.inputEl!=null){
            this.inputEl.removeEventListener('change',this.handleInputChange);
        }
    }
    handleAfterRender(){

        if(this.ResponseBar!=null){
            this.ResponseBar.init();
            this.ResponseBar.api.width(`${this.state.totalResponses}%`);
        }

        if(this.inputEl!=null){
            this.inputEl.addEventListener('change',this.handleInputChange.bind(this));
            let subscription = this.onChange.subscribe(e => {
                if (e.target.checked) {
                    this.api.select()
                } else {
                    this.api.unselect();
                }

            })
            this.subscriptions.push(subscription);
        }
    }
}

export { ChoiceComponent }