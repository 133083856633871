import User from './user';
import ArticlePage from './article-page';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
const windowScroll$ = fromEvent(window,'scroll');
const windowResize$ = fromEvent(window,'resize');
const windowOrientation$ = fromEvent(window,'orientationchange');

let Medscape = window.Medscape || {};
Medscape = $.extend(true,{},Medscape);


Medscape.User = Medscape.User || User;
let _ArticlePage = Medscape.ArticlePage || {};
Medscape.ArticlePage = $.extend(true,ArticlePage,_ArticlePage);

Medscape.getDomain = window.getDomain || function(profreg){
    var _domain ="";
    if (window.location.host.split('.').length == 4){
        _domain = window.location.host.split('.')[1] + ".";
    }
    if (window.location.host.split('.').length == 5){
        _domain = window.location.host.split('.')[1] + "."+ window.location.host.split('.')[2] + ".";
    }
    if(profreg=="profreg"||profreg=="login"){
        _domain = _domain.replace('staging.','').replace('proddev.','');
    }
    return _domain;
}
Medscape.setLocale = function(){
    this.locale = this.getLocale();
}
Medscape.getLocale = function(){
    let locale = window.locale;
    if (locale == null || locale.trim().length==0) {
        var host = location.host.split('.')[0];

        switch (host){
            case 'deutsch' : locale = 'de'; break;
            case 'espanol' : locale = 'es'; break;
            case 'francais' : locale = 'fr'; break;
            case 'portugues': locale = 'pt'; break;
            default : locale = 'us';
        }
    }
    return locale
}
Medscape.isMobile = window.isMobile;
Medscape.getBreakpoint = function(){
    return window.breakpoint
};

Medscape.windowEvents = {
    scroll: windowScroll$,
    resize: windowResize$,
    orientationChange: windowOrientation$
}
Medscape.getApiServer = function(){

    return `api.${this.getDomain()}medscape.com`;
}
Medscape.getImageServer = function(){


    return `img.${this.getDomain()}medscapestatic.com`;
}

Medscape.loadCSS = function(path){
    if(document.querySelector(`link[href="${path}"]`)==null){
        let linkEl = document.createElement('link');
        linkEl.setAttribute('rel','stylesheet');
        linkEl.setAttribute('type','text/css');
        linkEl.setAttribute('href',path);
        let headEl = document.getElementsByTagName('head')[0];
        headEl.appendChild(linkEl);
    }

}
Medscape.getSite = function(){
    let site = window.s_site || 'medscape';
    return site;
}
window.Medscape = Medscape;
export default Medscape;