import { QuestionComponent } from 'qna/core/questionnaire/form/question/question-component';
import { ChoiceComponent } from 'qna/core/questionnaire/form/question/choice/choice-component';
const TRANSITION_END_EVENTS = "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend";
/* Override default Component to handle animations */
class ResultsQuestionView extends QuestionComponent{
	constructor(el,questionState){
		super(el,questionState);
	}

	get ChoiceView(){
		return ChoiceView;
	}
}



class ChoiceView extends ChoiceComponent{
	constructor(el,state){
		super(el,state);
		this._transitionEndSubscriber = null;
		this._answerBar = null;
		this._icon = null;
		this._percentage = null;
	}
	get transitionEndSubsriber(){
		return this._transitionEndSubscriber;
	}
	set transitionEndSubsriber(subscriber){
		this._transitionEndSubscriber = subscriber;
	}
	get answerBar(){
		if(this._answerBar==null){
			this._answerBar = new AnswerBar(this.el.querySelector('.answer-bar-container'),this.state.totalResponses);
		}
		return this._answerBar;
	}
	get icon(){
		if(this._icon == null){
			this._icon = new Icon(this.el.querySelector('.input-container .svg-icon-container'));
		}
		return this._icon;
	}

	get percentage(){
		if(this._percentage==null){
			this._percentage = new Percentage(this.el.querySelector('.answer-percent'));
		}
		return this._percentage;
	}
	updateSelected(){}
	handleBeforeRender(){
		super.handleBeforeRender();
        this.answerBar.reset();
        this.icon.reset();
        this.percentage.reset();
		if(this.transitionEndSubsriber!=null){
			this.transitionEndSubsriber.unsubscribe();
			this.transitionEndSubsriber = null;
		}
	}
	handleAfterRender(){
		super.handleAfterRender();
    	setTimeout(()=>{
    		this.answerBar.render();
    		this.icon.render();
    		this.percentage.render();
    	},500)
	}
}


class Percentage{
	constructor(el){
		this.el = el;
		this.$el = $(el);
	}
	reset(){
		if(this.$el.length>0){
			this.el.classList.remove('ready');
		}
	}
	render(){
		if(this.$el.length>0){
			this.el.classList.add('ready');
		}
	}
}
class Icon{
	constructor(el){
		this.el = el;
		this.$el = $(el);
	}
	reset(){
		if(this.$el.length>0){
			this.el.classList.remove('ready');
		}
	}
	render(){
		if(this.$el.length>0){
			this.el.classList.add('ready');
		}
	}
}
class AnswerBar{
	constructor(el,width){
		this.el = el;
		this.$el = $(el);
		this.width = width;
		this.$answerBar = this.$el.find('.answer-bar');
	}
	reset(){
		if(this.el!=null){
			this.el.classList.remove('ready');
			this.$answerBar.width(0);
		}
	}
	render(){
		if(this.el!=null){
			this.$el.one(TRANSITION_END_EVENTS,()=>{
				this.$answerBar.width(`${this.width}%`);
			})
			this.el.classList.add('ready');
		}
	}
}
export { ResultsQuestionView }