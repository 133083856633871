
import { BehaviorSubject } from 'rxjs';
import ArticleQuestionnaire from './article-questionnaire';
import FastFiveQuestionnaire from './fast-five-questionnaire';
import SponsoredPoll from './sponsored-poll';

import Medscape from 'medscape';
import './handlebars-helpers';
import Handlebars from 'handlebars/runtime';
import TemplateTypes from './template-types';
if(Handlebars){
	window.Handlebars = window.Handlebars || Handlebars;
	//require("handlebar/article-templates")
	require('templates/article-partials');
	//console.log("test:",test);
}


/*
	QNA API Module
*/

class QNA extends BehaviorSubject{
	constructor(surveys=[]){
		super(surveys)
		this.surveys = surveys;
		this.formDataModels={}
		this._isInit = false;
	}
	get TemplateTypes(){
		return TemplateTypes;
	}
	get isInit(){
		return this._isInit;
	}
	set isInit(flag=this.isInit){
		this._isInit = flag;
	}
	init(_config={},callback=()=>{}){
		if(this.isInit==true){
			return;
		}
		this.isInit = true;

		const elements = Array.from(document.getElementsByClassName('qna-render'));

		let promises= [];
		elements.forEach( (qnaEl)=>{

			let promise = new Promise((resolve,reject)=>{
				let props = {
					config: $.extend (true,{},$(qnaEl)[0].dataset)
				}
				const questionnaire = new ArticleQuestionnaire(props,qnaEl);
				questionnaire.init().then((data)=>{
					//questionnaire.render();
					qnaEl.style.display = "block";
					this.surveys.push(questionnaire);
					this.next(this.surveys)
					resolve(questionnaire)
				},
				(error)=>{
					reject(error);
				});
			})
			promises.push(promise);
		})
		let promiseAll = Promise.all(promises);
		
		promiseAll.then(function(results){
			callback()
		})
		return promiseAll;

	}
	createArticlePoll(containerEl){
		const props = {
			config: (true,{},$(containerEl)[0].dataset)
		}
		let promise = new Promise((resolve,reject)=>{
			const questionnaire = new ArticleQuestionnaire(props,containerEl);
			questionnaire.init().then((data)=>{
				questionnaire.render();
				containerEl.style.display="block";
				this.surveys.push(questionnaire);
				this.next(this.surveys)
				resolve(questionnaire)
			},
			(error)=>{
				console.error("(qna-api): Create Article Poll Error:",error)
				reject(error);
			});
		})
		
		return promise;
	}
	createFastFivePoll(containerEl){
		let imageServer = "";
	    if(document.location.hostname.includes('localhost')==false){
	        imageServer = `//${Medscape.getImageServer()}`;
	    }
		let cssPath = `${imageServer}/medscape-core/qna-lib/css/fast-five-article-poll.min.css`;
		Medscape.loadCSS(cssPath);
		const props = {
			config: (true,{},$(containerEl)[0].dataset)
		}
		let promise = new Promise((resolve,reject)=>{
			const questionnaire = new FastFiveQuestionnaire(props,containerEl);
			questionnaire.init().then((data)=>{
				questionnaire.render();
				containerEl.style.display="block";
				this.surveys.push(questionnaire);
				this.next(this.surveys)
				resolve(questionnaire)
			},
			(error)=>{
				console.error("(qna-api): Create Article Poll Error:",error)
				reject(error);
			});
		})
		
		return promise;
	}
	createSponsoredPoll(containerEl){
		const props = {
			config: (true,{},$(containerEl)[0].dataset)
		}
		const poll = new SponsoredPoll(props,containerEl);
		let promise = new Promise((resolve,reject)=>{
			const subscription = poll.state.subscribe( 
				(data)=>{
					if(data!=null){
						poll.render();
						this.surveys.push(poll);
						this.next(this.surveys);
						resolve(poll);
						if(subscription){
							subscription.unsubscribe();
						}
					}
				},
				error=>{
					console.error("(qna-api): Create Sponsored Poll Error:",error)
					reject(error);
				});
				poll.init();
			
		});
		
		return promise;
	}

}

export default QNA;