import { QuestionState } from './question-state';
import { DISPLAY_TYPES } from './display-types';
class QuestionApi {
    constructor(state = null) {
        this._state = state;

    }
    get state() {
        if (this._state == null) {
            this._state = new QuestionState();
        }
        return this._state;
    }
    getChoiceState(choiceId) {
        return this.state.choices.find(choice => {
            return choice.choiceId == choiceId;
        })
    }
    checkAnswered() {
        const { displayType } = this.state;
        let answered = false;
        switch (displayType) {
            case DISPLAY_TYPES["TEXT"]:
            case DISPLAY_TYPES["TEXTAREA"]:
                answered = this.state.responseText != null && this.state.responseText.length>0;
                break;
            default:
                let selectedChoice = this.state.choices.find(choiceState => {
                    return choiceState.selected == true;
                });
                answered = selectedChoice != null;
                break;
        }
        this.state.answered = answered;
    }
    updateChoices(choiceState) {
        if (this.state.displayType == DISPLAY_TYPES["RADIO"] || this.state.displayType==DISPLAY_TYPES["SELECT"]) {
            if (choiceState.selected == true) {
                this.state.choices.forEach(choice => {
                    if (choice.choiceId != choiceState.choiceId) {
                        choice.selected = false;
                    }
                })
            }
        }
    }
    updateResponseText(text){
        if(this.state.displayType == DISPLAY_TYPES['TEXT'] || this.state.displayType == DISPLAY_TYPES['TEXTAREA']){
            this.state.responseText = text;
        }
    }
    updateSelectChoice(choiceId){
        let choiceState = this.getChoiceState(choiceId);
        choiceState.selected=true;
    }
    updateFilterChoices(questionSummary){
        this.state.totalResponses = 0;
        let filterChoices = questionSummary.pollSummaryChoiceResponses;
        let totalCount = questionSummary.totalCount;
        filterChoices.forEach(filterChoice=>{
            let choiceState = this.state.choices.find(choice=>{
                return choice.choiceId == filterChoice.choiceId;
            })
            choiceState.totalResponses = filterChoice.totalResponses;
            choiceState.totalAbsoluteResponseCount = filterChoice.totalCount;

        });
        this.state.totalResponses = totalCount;
    }
}

export { QuestionApi }