import { FormComponent } from 'qna/core/questionnaire/form/form-component';
import { default as QuestionTemplate } from 'templates/sponsored.questions';
import { default as ResultTemplate } from 'templates/sponsored.results';
import { ResultsQuestionView } from './results-question-view';
import { distinctUntilChanged } from 'rxjs/operators';
class FormView extends FormComponent{
	constructor(formState){
		super(formState);
		this._feedbackMessage = null;
		this._feedback = null;
		this._isSubmitted = false;
	}

	get template(){
		if(this.state.showResultsView==true){
			return ResultTemplate;
		}
		else{
			return QuestionTemplate;
		}
	}
	get QuestionView(){
		if(this.state.showResultsView==true){
			return ResultsQuestionView;
		}
		else{
			return super.QuestionView;
		}
	}

	get feedbackMessage(){
		return this._feedbackMessage;
	}
	set feedbackMessage(message=null){
		this._feedbackMessage = message;
		
		return this._feedbackMessage;
	}
	get feedback(){
		if(this.el.querySelector('.thank-you-message')!=null && this._feedback==null){
			this._feedback = new ThankYouMessage(this.el.querySelector('.thank-you-message'))
		}
		return this._feedback;
	}
	set feedback(obj=null){
		this._feedback=obj;
	}

	get isSubmitted(){
		return this._isSubmitted;
	}
	set isSubmitted(flag=this.isSubmitted){
		this._isSubmitted = flag;
	}
	get handlebarData(){
		let handlebarData = super.handlebarData;
		handlebarData.feedbackMessage = this.feedbackMessage;
		if(this.state.showResultsView){
			handlebarData.isSubmitted = this.isSubmitted;
			if(!this.isSubmitted){
				handlebarData.questions.forEach(question=>{
					question.choices.forEach(choice=>{
						choice.selected=false;
					})
				})
			}
		}
		handlebarData.expired = this.api.expired;
		return handlebarData;
	}
	init(){
		super.init();

		let unansweredPoll = this.state.questions.find(question=>{
			return question.answered==false;
		});

		if(unansweredPoll==null){
			this.isSubmitted = true;
			this.api.showResultsView();
		}
		else if(this.api.expired){
			this.api.showResultsView();
		}
		else{
			this.api.showQuestionsView();
		}
	}
	handleShowResultsChange(showResults=this.state.showResults){
		this.feedback = null;
		super.handleShowResultsChange(showResults);
	}
	handleBeforeRender(){
		super.handleBeforeRender();
		if(this.feedback!=null){
			this.feedback.beforeRender();
		}
	}
	handleAfterRender(){
		super.handleAfterRender();

		if(this.feedback!=null){
			this.feedback.afterRender();
		}
	}
}

class ThankYouMessage{
    constructor(el){
        this.el = el;
    }
    beforeRender(){
        if(this.el!=null){
            this.el.classList.remove('ready');
        }
    }
    afterRender(){
        if(this.el!=null){
            this.el.classList.add('ready');
        }
    }
}
export { FormView }