import { QuestionnaireState } from './questionnaire-state';
import { FormState } from './form/form-state';
import { Service } from 'qna/service';
class QuestionnaireApi{
	constructor(){
		this._state = null;
		this._service = null;

	}
	get id(){
		return this.state.questionnaireId;
	}
	get state(){
		if(this._state==null){
			this._state = new QuestionnaireState();
		}
		return this._state;
	}
	get service(){
		if(this._service==null){
			this._service = new Service(this.id)
		}
		return this._service;
	}
	setId(val){
		this.state.questionnaireId = val;
	}
	addFormState(formState){
		this.state.questionForms.push(formState);
	}
	getFormState(formId){
		return this.state.questionForms.find(formState=>{
			return formState.formId == formId;
		})
	}
	getFormData(formId){
		return this.service.getPoll(formId).then(response=>{
			let formState = new FormState(response);
			this.addFormState(formState);
			return formState;
		})

	}
	submitForm(formState){
		let formData = formState.toJSON();
		return this.service.submitPoll(formData);
	}
}

export { QuestionnaireApi }