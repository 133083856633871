import QNA from './qna/qna-article-api';
import qnaDictionary from 'qna/dictionary';
import Medscape from 'medscape';
window.qnaDictionary = qnaDictionary;
window.qna = window.qna || new QNA();

$().ready(function(){

    // set locale if not defined in jsp
    Medscape.setLocale();

    // Set up QnA in print page
    if (Medscape.ArticlePage.isPrintPage()) {
        window.isPrintPage = true;
        // disable native print function called in jsp
        let _print = window.print;
        let doPrint = false;
        window.print = function () {
            doPrint = true;
            console.log("Print Called!");
        };
        function checkPrint(){
            window.print = _print;
            if(doPrint){
                window.print();
            }
        }
        window.qna.init().then( 
            values=>{
                if(Medscape.ArticlePage.isPrintPage()){
                    checkPrint();
                }
            },
            error=>{
                checkPrint();
            })
        .catch(error=>{
            checkPrint();
        })
    }
});
