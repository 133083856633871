import svgParser from './svg-parser';
import { GlobalSvgSprites } from './global-svg-sprites';
import { isIE } from 'utils/is-ie';
const defaultViewBox = "0 0 24 24";

export default class SvgFactory {
    constructor(config = new SvgFactoryConfig()) {
        this._iconName = config.iconName;
        this._shape = config.shape;
        this._viewBox = config.viewBox;
        this._defs = config.defs;

    }
    get iconName() {
        return this._iconName;
    }
    get shape() {
        return this._shape;
    }
    get viewBox() {
        return this._viewBox;
    }

    get defs() {
        return this._defs;
    }
    // Get regular SVG Icon
    get icon() {
        console.log("SVG ICON");
        const { iconName } = this;
        const svgString = `
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="${this.viewBox}" class="${iconName}-icon">
					${this.defs}
					${this.shape}
			</svg>`;
        return svgParser(svgString);
    }


    // Creates Node for Global SVG Sprites
    get symbol() {
        const spriteSymbol = `
			<symbol id="${this.iconName}" viewBox="${this.viewBox}">
				${this.defs}
				${this.shape}
			</symbol>
		`
        return svgParser(spriteSymbol);
    }
    // Get SVG that references Global Svg Sprites
    get useSprite() {
        if (isIE()) {
            return this.icon;
        } else {
            GlobalSvgSprites.require(this.iconName, this.symbol).then(data => {})
            const iconName = this.iconName;
            const svgString = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="${iconName}-icon"></svg>`
            let svgEl = svgParser(svgString);
            let useEl = document.createElementNS('http://www.w3.org/2000/svg', 'use');
            useEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${iconName}`);
            svgEl.appendChild(useEl);
            console.log("svgEl:", svgEl);
            return svgEl
        }
    }
    get useSpriteString() {
        GlobalSvgSprites.require(this.iconName, this.symbol);
        const iconName = this.iconName;
        let svgString = '';
        if (isIE()) {
            svgString = `
			<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="${this.viewBox}" class="${iconName}-icon">
					${this.defs}
					${this.shape}
			</svg>`;
        } else {
            svgString = `
	            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="${iconName}-icon">
	            	<use xlink:href="#${iconName}"></use>
	            </svg>
        	`
        }
        return svgString;
    }
}

class SvgFactoryConfig {
    constructor() {
        this._iconName = "";
        this._shape = "";
        this._viewBox = defaultViewBox;
        this._defs = "";
    }
    get iconName() {
        return this._iconName;
    }
    set iconName(val = "") {
        this._iconName = val;
    }
    get shape() {
        return this._shape;
    }
    set shape(val = "") {
        this._shape = val;
    }
    get viewBox() {
        return this._viewBox;
    }
    set viewBox(val = "") {
        this._viewBox = val;
    }
    get defs() {
        return this._defs;
    }
    set defs(val = "") {
        this._defs = val;
    }
}

export { SvgFactory }
export { SvgFactoryConfig }