import { Subject } from 'rxjs';
import { RenderComponent, RENDER_STATES } from 'qna/core/render-component';
import { FilterItemComponent } from './filter-item-component';
import { FiltersApi } from './filters-api';
import { FILTER_KEYS } from './filter-keys';
class FiltersComponent extends RenderComponent{

    constructor(el){
        super();
        this._el = el;
        this._state = null;
        this._filterItems = null;
        this._api = null;
        this._subscriptions = [];
        this._initSubscriptions = [];
    }

    get el(){
        return this._el;
    }
    get api(){
        if(this._api==null){
            this._api = new FiltersApi();
        }
        return this._api;
    }
    get state(){
        return this.api.state;
    }
    get initSubscriptions(){
        return this._initSubscriptions;
    }
    get subscriptions(){
        return this._subscriptions;
    }
    set subscriptions(subscriptions=[]){
        this._subscriptions = subscriptions;
    }
    get filterItemEls(){
        return [...this._el.querySelectorAll('.filter-item-component')]
    }
    get filterItems(){
        if(this._filterItems == null){
            this._filterItems = this.filterItemEls.map(el=>{
                return new FilterItemComponent(el);
            })
        }
        return this._filterItems;
    }
    set filterItems(filterItem=null){
        this._filterItems = null;
    }
    init(){
        super.init();
        this.filterItems.forEach(filter=>{
            filter.init();
            let subscription = filter.onChange.subscribe(state=>{
                this.handleFilterItemChange(state);
            })
            this.initSubscriptions.push(subscription);
        })
    }
    destroy(){
        super.destroy();
        this.filterItems.forEach(filter=>{
            filter.destroy();
        })
        this.initSubscriptions.forEach(subscription=>{
            subscription.unsubscribe();
        })
        this.subscriptions.forEach(subscriptoin=>{
            subscription.unsubscribe();
        })
    }
    handleFilterItemChange(filterItemState){
        const { key, id } = filterItemState;
        if(key==FILTER_KEYS["PROFESSION"]){
            this.api.setProfessionId(id)
        }
        else if(key==FILTER_KEYS["SPECIALTY"]){
            this.api.setSpecialtyId(id);
        }
    }

    handleBeforeRender() {
        this.filterItems.forEach(filterItem=>{
            filterItem.beforeRender();
            filterItem.destroy();
        })
        if(this.renderSubscriptions!=null){
            this.renderSubscriptions.forEach(subscription=>{
                subscription.unsubscribe();
            })
        }
        this.filterItems = null;
    }
    handleAfterRender() {
        this.filterItems.forEach(filterItem=>{
            filterItem.afterRender();
        })
    }
}

export { FiltersComponent }