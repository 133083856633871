import svgParser from './svg-parser';
/*
    Creates a SVG Sprite sheet
*/
class SvgSprites {
    constructor() {
        this._el = null;
        this._svgSpritesEl = null;
        this._map = null;
        this._observer = null;
        this._parsedSvgEl = null
    }
    get el() {
        /* Container for the SVG sprites */
        if (this._el == null) {
            let el = document.createElement('section');
            el.classList.add('svg-sprites-container');
            //el.style.display = "none";
            el.appendChild(this.parsedSvgEl);
            
            this._el = el;
        }

        return this._el;
    }

    get svgSpritesEl() {
        /* <SVG> Node Element */
        if (this._svgSpritesEl == null) {
            this._svgSpritesEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            this._svgSpritesEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
            this._svgSpritesEl.setAttribute("version", "1.1");
            if(this._svgSpritesEl.classList == null){
                // IE doesn't support classList for svg elements
                this._svgSpritesEl.setAttribute("class","svg-sprites");   
            }
            else{
                this._svgSpritesEl.classList.add('svg-sprites');
            }
            this.observeSprites();
        }
        return this._svgSpritesEl;
    }
    get map() {
        /* Keep track of sprites in the SVG */
        if (this._map == null) {
            this._map = new Map()
        }
        return this._map;
    }

    get parsedSvgEl() {
        /* Remove previous parsed SVG from the DOM and create a new instance */
        if(this._parsedSvgEl!=null){
            this._parsedSvgEl.remove();
        }
        // IE doesn't suppoert outerHTML
        let svgString = this.svgSpritesEl.outerHTML;
        if(svgString==null){
            let svg = this.svgSpritesEl;
            svg.removeAttribute("xmlns");
            svgString = new XMLSerializer().serializeToString(svg);
        }
        this._parsedSvgEl = svgParser(svgString);
        return this._parsedSvgEl;
    }

    observeSprites() {
        /*
            SVG needs to be re-parsed every time a new sprite is added
        */
        const config = { attributes: false, childList: true, subtree: true };
        const callback = (mutationsList, observer) => {
            this.el.appendChild(this.parsedSvgEl);
        }
        let observer = new MutationObserver(callback);
        observer.observe(this.svgSpritesEl, config);
    }
    require(key = null, el = null) {
        /* 
            Adds a new SVG sprite if it doesn't exist 
            returns a Promise
        */
        let spritesMap = this.map;
        let svgSpritesEl = this.svgSpritesEl;
        let promise = new Promise((resolve, reject) => {
            if (key != null && el != null && !spritesMap.has(key)) {
                spritesMap.set(key, el);
                svgSpritesEl.appendChild(el);
            }
            resolve({
                key,
                el
            });
        })
        return promise
    }
}


//document.body.appendChild(globalSvgSprites.el);
export { SvgSprites };