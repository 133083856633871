import { ChoiceState } from './choice-state';
class ChoiceApi{
	constructor(state = null){
		this._state = state;

	}
	get state(){
		if(this._state==null){
			this._state = new ChoiceState();
		}
		return this._state;
	}

	select(){
		this.state.selected=true;
	}

	unselect(){
		this.state.selected=false;
	}
}

export { ChoiceApi }