import { QuestionnaireComponent } from 'qna/core/questionnaire/questionnaire-component';
import { FormView } from './form-view';
import Medscape from 'medscape'
import { RENDER_STATES } from 'qna/core/render-component';
export default class FastFiveQuestionnaire extends QuestionnaireComponent {
    constructor(props, containerEl) {
        super(containerEl);
        this._formView = null
        this._onSubmitSource = null;
    }
    get type(){
        return "FAST_FIVE_QUESTIONNAIRE";
    }
    get formView() {
        if (this._formView == null) {
            this._formView = new FormView();
        }
        return this._formView;
    }

    set formView(formView) {
        this._formView = formView;
    }

    get onSubmitSource(){
        return this._onSubmitSource;
    }
    set onSubmitSource(source){
        this._onSubmitSource = source;
    }

    init() {
        super.init();
        this.el.classList.add("fast-five-article-poll");
        let formId = this.el.dataset.formid;
        
        return this.api.getFormData(formId).then(formState=>{
            this.handleFormData(formState);
            return formState;
        })
    }
    handleFormData(formState){
        let formId = formState.formId;
        this.formView = new FormView(formState);
        if (this.el.dataset.render == "3") {
            this.formView.api.showResultsView();
        } else if (this.el.dataset.render == "2") {
            this.formView.api.showQuestionsView();
        }
        this.formView.init();
        this.formView.render();
        this.el.appendChild(this.formView.el);
        this.formView.renderObserver.subscribe(state=>{
            if(state.type==RENDER_STATES.BEFORE){
                this.handleFormViewBeforeRender();
            }
            else if(state.type==RENDER_STATES.AFTER){
                this.handleFormViewAfterRender();
            } 
        })
    }
    handleFormViewBeforeRender(){
        if(this.onSubmitSource != null){
            this.onSubmitSource.unsubscribe();
            this.onSubmitSource = null;
        }
    }
    handleFormViewAfterRender(){
        if (this.formView.onSubmit != null) {
            this.onSubmitSource = this.formView.onSubmit.subscribe(data => {
                let { formState } = data;
                if (this.formView.api.isValidForm) {
                    this.api.submitForm(formState).then(data=>{
                        const { curpage, totalpages } = this.el.dataset;
                        Medscape.ArticlePage.loadNextPage(curpage,totalpages,this.el);
                    },
                    error=>{
                        console.error("Submit Poll Error:",error);
                    });
                } else {
                    console.log("Form is inValid");
                }
            })
        }
    }

}

