import Handlebars from "handlebars/runtime";Handlebars.registerPartial("Article.question.default.display.checkboxes", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <label class=\"choice-component\" for=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":2,"column":52},"end":{"line":2,"column":64}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":2,"column":97},"end":{"line":2,"column":111}}}) : helper)))
    + "\">\n            <input \n                type=\"checkbox\" \n                value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":34}}}) : helper)))
    + " \n                id=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":6,"column":27},"end":{"line":6,"column":39}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" \n                name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" \n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":11,"column":26}}})) != null ? stack1 : "")
    + "                data-question-id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\" \n                data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":46}}}) : helper)))
    + "\">\n            <div class=\"answer-bar\"></div>\n            <div class=\"answer-content\">\n                <ans>\n                    <span></span>\n                </ans>\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":19,"column":19},"end":{"line":19,"column":35}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n        </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                    class=\"answer-select\" \n                    checked \n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.question.default.display.input-text", Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label for=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":37}}}) : helper)))
    + "-1\">\n        <div class=\"answer-content\"><input type=\"text\" maxlength='4000' name=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":2,"column":85},"end":{"line":2,"column":99}}}) : helper)))
    + "\" data-question-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":2,"column":119},"end":{"line":2,"column":133}}}) : helper)))
    + "\" value=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"responseText") || (depth0 != null ? lookupProperty(depth0,"responseText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"responseText","hash":{},"data":data,"loc":{"start":{"line":2,"column":142},"end":{"line":2,"column":160}}}) : helper))) != null ? stack1 : "")
    + "\">\n        </div>\n\n    </label>";
},"useData":true}));
Handlebars.registerPartial("Article.question.default.display.radio-buttons", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <label class=\"choice-component\" for=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":60}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\"  data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":94},"end":{"line":3,"column":108}}}) : helper)))
    + "\">\n\n        <input type=\"radio\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":46}}}) : helper)))
    + " id=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":70}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" data-question-id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":162},"end":{"line":5,"column":176}}}) : helper)))
    + "\"\n            "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":55}}})) != null ? stack1 : "")
    + "\n        >\n        <div class=\"answer-content\">\n            <ans>\n                <span></span>\n            </ans>\n            <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":12,"column":15},"end":{"line":12,"column":31}}}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n\n    </label>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.question.default.display.select-options", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                    <option class=\"question-label\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":10,"column":58},"end":{"line":10,"column":70}}}) : helper)))
    + "\"  data-question-id=\""
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\" \n                    data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":48}}}) : helper)))
    + "\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\n                    </option>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label for=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":37}}}) : helper)))
    + "-1\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":71}}}) : helper)))
    + "\">\n        <div class=\"answer-content\">\n\n            <select  id=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":46}}}) : helper)))
    + "-1\" data-question-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":82}}}) : helper)))
    + "\">\n\n                <option>---  "
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":29},"end":{"line":6,"column":48}}}))
    + " ---</option>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":15,"column":25}}})) != null ? stack1 : "")
    + "\n            </select>\n        </div>\n    </label>";
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.question.default.display.textarea", Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label for=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":37}}}) : helper)))
    + "-1\">\n        <div class=\"answer-content\">\n            <textarea maxlength='4000' name=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":3,"column":52},"end":{"line":3,"column":66}}}) : helper)))
    + "\" data-question-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":3,"column":86},"end":{"line":3,"column":100}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"responseText") || (depth0 != null ? lookupProperty(depth0,"responseText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"responseText","hash":{},"data":data,"loc":{"start":{"line":3,"column":102},"end":{"line":3,"column":120}}}) : helper))) != null ? stack1 : "")
    + "</textarea>\n\n        </div>\n\n    </label>";
},"useData":true}));
Handlebars.registerPartial("Article.question.default", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial((lookupProperty(helpers,"renderPartial")||(depth0 && lookupProperty(depth0,"renderPartial"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"handlebarData") : depth0)) != null ? lookupProperty(stack1,"displayType") : stack1),{"name":"renderPartial","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":50}}}),depth0,{"data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"1",{"name":"if_eq","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":1},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"2",{"name":"if_eq","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":1},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"3",{"name":"if_eq","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":1},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"4",{"name":"if_eq","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":1},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"5",{"name":"if_eq","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":1},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"6",{"name":"if_eq","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":1},"end":{"line":31,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Article.question.default.display.radio-buttons"),depth0,{"name":"Article.question.default.display.radio-buttons","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Article.question.default.display.checkboxes"),depth0,{"name":"Article.question.default.display.checkboxes","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Article.question.default.display.select-options"),depth0,{"name":"Article.question.default.display.select-options","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"Article.question.default.display.input-text"),depth0,{"name":"Article.question.default.display.input-text","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"Article.question.default.display.textarea"),depth0,{"name":"Article.question.default.display.textarea","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"Article.question.default.display.radio-buttons"),depth0,{"name":"Article.question.default.display.radio-buttons","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"handlebarData") : depth0)) != null ? lookupProperty(stack1,"displayType") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":32,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));
Handlebars.registerPartial("Article.question", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h2>"
    + alias3((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":33}}}))
    + " "
    + alias3((lookupProperty(helpers,"counter")||(depth0 && lookupProperty(depth0,"counter"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"counter","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":52}}}))
    + " "
    + alias3((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"of",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":70}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"questionsLength") || (depth0 != null ? lookupProperty(depth0,"questionsLength") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"questionsLength","hash":{},"data":data,"loc":{"start":{"line":3,"column":71},"end":{"line":3,"column":90}}}) : helper)))
    + "</h2>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input type=\"hidden\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"counter")||(depth0 && lookupProperty(depth0,"counter"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),{"name":"counter","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":54}}}))
    + "\" name=\"counter\"/>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"questiontext12\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"introduction") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"question-content with-intro\">\n                        <p>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"questionText") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"question-content\">\n                        <p>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"questionText") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question-container question-component\" data-question-id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"questionsLength") : depth0),">",1,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    <fieldset>\n        <div class=\"qna-question\" id=\"qna-question-"
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\" data-question-id=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\">\n            <input type=\"hidden\" value=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\" name=\"questionId\"/>\n            <legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "            </legend>\n\n\n"
    + ((stack1 = container.invokePartial((lookupProperty(helpers,"getQuestionType")||(depth0 && lookupProperty(depth0,"getQuestionType"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"questionTypeId") : depth0),{"name":"getQuestionType","hash":{},"data":data,"loc":{"start":{"line":29,"column":15},"end":{"line":29,"column":52}}}),depth0,{"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n    </fieldset>\n</div>";
},"usePartial":true,"useData":true}));
Handlebars.registerPartial("Article.question.matrix", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <ul class=\"qna-qmatrix\">\n        <li>\n            <ul class=\"qna-qmatrix-header\">\n                <li class=\"qna-qmatrix-question\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "")
    + "\n            </ul>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"matrixQuestions") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":45,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"qna-qmatrix-answer\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":7,"column":51},"end":{"line":7,"column":67}}}) : helper))) != null ? stack1 : "")
    + "</li>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"matrixQuestions") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":44,"column":21}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                <li>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"matrixQuestions") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1),">","1",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":18,"column":31}}})) != null ? stack1 : "")
    + "                    <ul class=\"qna-qmatrix-content matrix-question-component\" data-question-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":19,"column":96},"end":{"line":19,"column":110}}}) : helper)))
    + "\">\n                        <li class=\"qna-qmatrix-question\">\n                            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"questionText") : depth0), depth0)) != null ? stack1 : "")
    + "\n                        </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":41,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"hidden\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"counter")||(depth0 && lookupProperty(depth0,"counter"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),{"name":"counter","hash":{},"data":data,"loc":{"start":{"line":17,"column":52},"end":{"line":17,"column":70}}}))
    + "\" name=\"counter\"/>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"qna-qmatrix-answer choice-component\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":24,"column":92},"end":{"line":24,"column":104}}}) : helper)))
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(data && lookupProperty(data,"key")),"===",0,{"name":"ifCond","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":27,"column":43}}})) != null ? stack1 : "")
    + "                                <input type=\"hidden\" value=\""
    + alias4((lookupProperty(helpers,"counter")||(depth0 && lookupProperty(depth0,"counter"))||alias2).call(alias1,(data && lookupProperty(data,"key")),{"name":"counter","hash":{},"data":data,"loc":{"start":{"line":28,"column":60},"end":{"line":28,"column":76}}}))
    + "\" name=\"counter\"/>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"counter") : depth0),"===",((stack1 = (depths[1] != null ? lookupProperty(depths[1],"choices") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1),{"name":"ifCond","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":31,"column":43}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":32,"column":32},"end":{"line":36,"column":39}}})) != null ? stack1 : "")
    + "                                <label for=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":37,"column":51},"end":{"line":37,"column":63}}}) : helper)))
    + "-"
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\">\n                                    <span></span>\n                                </label>\n                            </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"choiceText") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type=\"radio\" id=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":33,"column":67},"end":{"line":33,"column":79}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":33,"column":102},"end":{"line":33,"column":114}}}) : helper)))
    + " name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" checked data-question-id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\">\n";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <input type=\"radio\" id=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":35,"column":67},"end":{"line":35,"column":79}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":35,"column":102},"end":{"line":35,"column":114}}}) : helper)))
    + " name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" data-question-id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"1",{"name":"if_eq","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":48,"column":10}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.results.display-types", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial((lookupProperty(helpers,"renderPartial")||(depth0 && lookupProperty(depth0,"renderPartial"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"handlebarData") : depth0)) != null ? lookupProperty(stack1,"displayType") : stack1),{"name":"renderPartial","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":50}}}),depth0,{"data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"1",{"name":"if_eq","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":1},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"2",{"name":"if_eq","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":1},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayType") : depth0),"3",{"name":"if_eq","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":1},"end":{"line":12,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Article.results.display.radio-buttons"),depth0,{"name":"Article.results.display.radio-buttons","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Article.results.display.checkboxes"),depth0,{"name":"Article.results.display.checkboxes","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Article.results.display.select-options"),depth0,{"name":"Article.results.display.select-options","data":data,"indent":"\t    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"handlebarData") : depth0)) != null ? lookupProperty(stack1,"displayType") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));
Handlebars.registerPartial("Article.results.display.checkboxes", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <label for=\"poll-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":33}}}) : helper)))
    + "-"
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"labelClasses") || (depth0 != null ? lookupProperty(depth0,"labelClasses") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelClasses","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":73}}}) : helper)))
    + " choice-component\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":108},"end":{"line":3,"column":122}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"answer-content\">\n            <ans>\n                <span></span>\n            </ans>\n            <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":18,"column":15},"end":{"line":18,"column":31}}}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </label>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":53}}}) : helper)))
    + " id=\"poll-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":63},"end":{"line":5,"column":75}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" class=\"answer-select\" checked disabled>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":53}}}) : helper)))
    + " id=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":7,"column":65},"end":{"line":7,"column":77}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" disabled>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"answer-bar\" style=\"width:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":67}}}) : helper)))
    + "%\"></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"answer-bar\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"answer-percent\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":63}}}) : helper)))
    + "%</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"answer-percent\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":26,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.results.display.radio-buttons", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label for=\"poll-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":33}}}) : helper)))
    + "-"
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"labelClasses") || (depth0 != null ? lookupProperty(depth0,"labelClasses") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelClasses","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":73}}}) : helper)))
    + " choice-component\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":108},"end":{"line":3,"column":120}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"answer-content\">\n            <ans>\n                <span></span>\n            </ans>\n            <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":18,"column":15},"end":{"line":18,"column":31}}}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </label>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"radio\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":38},"end":{"line":5,"column":50}}}) : helper)))
    + " id=\"poll-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":60},"end":{"line":5,"column":72}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" checked disabled >\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"radio\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":7,"column":38},"end":{"line":7,"column":50}}}) : helper)))
    + " id=\"poll-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":7,"column":60},"end":{"line":7,"column":72}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" disabled>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"answer-bar\" style=\"width:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":67}}}) : helper)))
    + "%\"></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"answer-bar\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"answer-percent\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":63}}}) : helper)))
    + "%</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"answer-percent\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":26,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.results.display.select-options", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "    <label for=\"poll-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":33}}}) : helper)))
    + "-"
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"classval") || (depth0 != null ? lookupProperty(depth0,"classval") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classval","hash":{},"data":data,"loc":{"start":{"line":5,"column":57},"end":{"line":5,"column":69}}}) : helper)))
    + "\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":5,"column":87},"end":{"line":5,"column":101}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"answer-content\">\n            <ans>\n                <span></span>\n            </ans>\n            <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":20,"column":15},"end":{"line":20,"column":31}}}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"var")||(depth0 && lookupProperty(depth0,"var"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"classval","answer-correct",{"name":"var","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":43}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":53}}}) : helper)))
    + " id=\"poll-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":75}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" class=\"answer-select\" checked disabled>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":9,"column":41},"end":{"line":9,"column":53}}}) : helper)))
    + " id=\"answer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":9,"column":65},"end":{"line":9,"column":77}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" name=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"counter") : depths[1]), depth0))
    + "\" disabled>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"answer-bar\" style=\"width:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":67}}}) : helper)))
    + "%\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"answer-bar\"></div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"answer-percent\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":22,"column":45},"end":{"line":22,"column":63}}}) : helper)))
    + "%</span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"answer-percent\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.results.filters", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"filter-item-component\" data-key=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":49},"end":{"line":3,"column":57}}}) : helper)))
    + "\">\n        <span class=\"qna-res-title\">"
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"titleLocaleKey") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":61}}}))
    + ":</span>\n        <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":28}}}) : helper)))
    + "Id\">\n            <div class=\"qna-poll-data\">\n                <select name=\"select-"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":45}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":51},"end":{"line":7,"column":59}}}) : helper)))
    + "Id\" class=\"qna-poll-select\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":98},"end":{"line":7,"column":106}}}) : helper)))
    + "\" \n                style=\"background: #fefefe url(//img.medscapestatic.com/pi/global/icons/icon-arrow-down-black.png) no-repeat top 15px right 10px; background-size:13px 10px\"\n                >\n                    <option value=\"0\">"
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"localeName") : depth0),0,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":61}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":15,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </label>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value="
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":38},"end":{"line":12,"column":44}}}) : helper)))
    + ">\n                            "
    + alias3((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"localeName") : depths[1]),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":28},"end":{"line":13,"column":55}}}))
    + "\n                        </option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"qna-result-filter filters-component\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"filters") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("Article.results", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showHeader") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    <fieldset>\n        <div class=\"qna-question answered\" id=\"qna-question-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\" data-question-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\">\n            <legend>\n                <div class=\"question-content\">\n                    <p>"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"questionText") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n                </div>\n            </legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"poll") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"Article.results.display-types"),depth0,{"name":"Article.results.display-types","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        </div>\n    </fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h2>"
    + alias3((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":33}}}))
    + "  "
    + alias3(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":44}}}) : helper)))
    + " "
    + alias3((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"of",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":60}}}))
    + " "
    + alias3(((helper = (helper = lookupProperty(helpers,"questionsLength") || (depth0 != null ? lookupProperty(depth0,"questionsLength") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"questionsLength","hash":{},"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":80}}}) : helper)))
    + "</h2>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input type=\"hidden\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":45}}}) : helper)))
    + "\" name=\"counter\" />\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"qna-poll-chart\">"
    + container.escapeExpression((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"peerChose",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":67}}}))
    + ":</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"qna-poll-chart\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <p class=\"answerrow\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"discussion") || (depth0 != null ? lookupProperty(depth0,"discussion") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"discussion","hash":{},"data":data,"loc":{"start":{"line":28,"column":29},"end":{"line":28,"column":45}}}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":39,"column":13}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"explanation") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n                    <p class=\"answerrow\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"explanation") || (depth0 != null ? lookupProperty(depth0,"explanation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"explanation","hash":{},"data":data,"loc":{"start":{"line":35,"column":41},"end":{"line":35,"column":58}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question-component\" data-question-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":64}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAnsTable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discussion") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":26,"column":0},"end":{"line":40,"column":7}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true}));
Handlebars.registerPartial("SponsoredPoll.question.display-types", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <label class=\"choice-component sponsored-poll-input-choice \n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),1,{"name":"if_eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),2,{"name":"if_eq","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":22}}})) != null ? stack1 : "")
    + "            "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"handlebarData") : depth0)) != null ? lookupProperty(stack1,"inputType") : stack1), depth0))
    + " "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":84}}})) != null ? stack1 : "")
    + " \n    choice-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":11,"column":11},"end":{"line":11,"column":23}}}) : helper)))
    + "\" for=\"choice-"
    + alias4(alias3((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":11,"column":55},"end":{"line":11,"column":67}}}) : helper)))
    + "\"  data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":11,"column":86},"end":{"line":11,"column":100}}}) : helper)))
    + "\">\n            <input\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),1,{"name":"if_eq","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":15,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),2,{"name":"if_eq","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":18,"column":22}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":55}}})) != null ? stack1 : "")
    + "\n            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":20,"column":19},"end":{"line":20,"column":31}}}) : helper)))
    + "\" id=\"choice-"
    + alias4(alias3((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":20,"column":62},"end":{"line":20,"column":74}}}) : helper)))
    + "\" name=\"question-"
    + alias4(alias3((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":20,"column":126},"end":{"line":20,"column":140}}}) : helper)))
    + "\" style=\"display: none;\"\n            >\n        <div class=\"answer-content\">\n            <div class=\"input-container\">\n                <ans>\n                    <span>\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),2,{"name":"if_eq","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":28,"column":34}}})) != null ? stack1 : "")
    + "                    </span>\n\n                </ans>\n\n            </div>\n            <div class=\"text-container\">\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":35,"column":19},"end":{"line":35,"column":35}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n        </div>\n\n    </label>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                radio \n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                checkbox \n";
},"6":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"8":function(container,depth0,helpers,partials,data) {
    return "                type=\"radio\"\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                type=\"checkbox\"\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = (lookupProperty(helpers,"renderSVG")||(depth0 && lookupProperty(depth0,"renderSVG"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"correct-checkmark",{"name":"renderSVG","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":64}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":41,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("SponsoredPoll.question", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"question-content with-intro\">\n                        <p>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"questionText") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"question-content\">\n                        <p>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"questionText") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"question-introduction\">\n                    <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"introduction") || (depth0 != null ? lookupProperty(depth0,"introduction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"introduction","hash":{},"data":data,"loc":{"start":{"line":23,"column":23},"end":{"line":23,"column":41}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question-container question-component\" data-question-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\n    <fieldset>\n        <div class=\"qna-question\" id=\"qna-question-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\" data-question-id=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\">\n            <input type=\"hidden\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\" name=\"questionId\"/>\n            <legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "")
    + "            </legend>\n\n            <div class=\"display-type-container\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SponsoredPoll.question.display-types"),depth0,{"name":"SponsoredPoll.question.display-types","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n    </fieldset>\n</div>";
},"usePartial":true,"useData":true}));
Handlebars.registerPartial("SponsoredPoll.results.display-types", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label \n    class=\"choice-component sponsored-poll-input-choice \n        "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"handlebarData") : depth0)) != null ? lookupProperty(stack1,"inputType") : stack1), depth0))
    + " \n        "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":52}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"hasCorrectChoice") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    \" data-choice-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":36}}}) : helper)))
    + "\">\n            <input\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias4).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),1,{"name":"if_eq","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":20,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias4).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),2,{"name":"if_eq","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":22}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":55}}})) != null ? stack1 : "")
    + "\n            value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":25,"column":19},"end":{"line":25,"column":31}}}) : helper)))
    + "\" id=\"choice-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "-"
    + alias2(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":25,"column":62},"end":{"line":25,"column":74}}}) : helper)))
    + "\" name=\"question-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\" data-choice-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":25,"column":126},"end":{"line":25,"column":140}}}) : helper)))
    + "\" style=\"display: none;\"\n            >\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"answer-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"hasCorrectChoice") : depths[1]),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "")
    + "             <div class=\"text-container\">\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":52,"column":19},"end":{"line":52,"column":35}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"poll") : depths[1]),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":61,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"correct") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                correct\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                    wrong\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                type=\"radio\"\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                type=\"checkbox\"\n";
},"14":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"answer-bar-container\">\n                <div class=\"answer-bar\" style=\"width:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":30,"column":53},"end":{"line":30,"column":71}}}) : helper)))
    + "%\"></div>\n            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-container\">\n                    <ans>\n                        <span class=\"svg-icon-container\">\n                        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"correct") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":39,"column":28},"end":{"line":45,"column":35}}})) != null ? stack1 : "")
    + "                        \n                        </span>\n                    </ans>\n                </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = (lookupProperty(helpers,"renderSVG")||(depth0 && lookupProperty(depth0,"renderSVG"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"correct-checkmark",{"name":"renderSVG","hash":{},"data":data,"loc":{"start":{"line":40,"column":32},"end":{"line":40,"column":68}}})) != null ? stack1 : "")
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":32},"end":{"line":44,"column":39}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + ((stack1 = (lookupProperty(helpers,"renderSVG")||(depth0 && lookupProperty(depth0,"renderSVG"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"wrong-x",{"name":"renderSVG","hash":{},"data":data,"loc":{"start":{"line":43,"column":36},"end":{"line":43,"column":62}}})) != null ? stack1 : "")
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"percentage-container\">\n                    <div class=\"percentage-wrapper\">\n                        <span class=\"answer-percent\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":58,"column":53},"end":{"line":58,"column":71}}}) : helper)))
    + "%</span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":64,"column":9}}})) != null ? stack1 : "")
    + "\n";
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("SponsoredPoll.results", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset>\n        <div class=\"qna-question answered\" id=\"qna-question-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\" data-question-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"questionId") : depth0), depth0))
    + "\">\n            <legend>\n                <div class=\"question-content\">\n                    <p>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"questionText") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n                </div>\n            </legend>\n            <div class=\"display-type-container "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"poll") : depth0),false,{"name":"if_eq","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":88}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"SponsoredPoll.results.display-types"),depth0,{"name":"SponsoredPoll.results.display-types","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " not-poll ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <p class=\"answerrow\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"discussion") || (depth0 != null ? lookupProperty(depth0,"discussion") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"discussion","hash":{},"data":data,"loc":{"start":{"line":18,"column":29},"end":{"line":18,"column":45}}}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":29,"column":13}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"explanation") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n                    <p class=\"answerrow\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"explanation") || (depth0 != null ? lookupProperty(depth0,"explanation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"explanation","hash":{},"data":data,"loc":{"start":{"line":25,"column":41},"end":{"line":25,"column":58}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"question-introduction\">\n        <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"introduction") || (depth0 != null ? lookupProperty(depth0,"introduction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"introduction","hash":{},"data":data,"loc":{"start":{"line":34,"column":11},"end":{"line":34,"column":29}}}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sponsored-poll-results question-component\" data-question-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":73},"end":{"line":1,"column":87}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAnsTable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discussion") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":0},"end":{"line":36,"column":7}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true}));
Handlebars.registerPartial("FastFiveArticle.question.display-types", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <label class=\"choice-component "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":79}}})) != null ? stack1 : "")
    + "\" for=\"choice-"
    + alias4(alias3((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":111},"end":{"line":3,"column":123}}}) : helper)))
    + "\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":3,"column":141},"end":{"line":3,"column":155}}}) : helper)))
    + "\">\n        <div class=\"input-container\">\n            <input\n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),1,{"name":"if_eq","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),2,{"name":"if_eq","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":11,"column":22}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":55}}})) != null ? stack1 : "")
    + "\n            value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":13,"column":19},"end":{"line":13,"column":31}}}) : helper)))
    + "\" id=\"choice-"
    + alias4(alias3((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":13,"column":62},"end":{"line":13,"column":74}}}) : helper)))
    + "\" name=\"question-"
    + alias4(alias3((depths[1] != null ? lookupProperty(depths[1],"questionId") : depths[1]), depth0))
    + "\" data-choice-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":13,"column":126},"end":{"line":13,"column":140}}}) : helper)))
    + "\" style=\"display: none;\"\n            >\n            <div class=\"svg-icon\"> \n"
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),1,{"name":"if_eq","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":18,"column":26}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"displayType") : depths[1]),2,{"name":"if_eq","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":21,"column":26}}})) != null ? stack1 : "")
    + "               \n            </div>\n        </div>\n        <div class=\"text-container\">\n            <span>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":34}}}) : helper))) != null ? stack1 : "")
    + "</span>\n        </div>\n\n    </label>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"4":function(container,depth0,helpers,partials,data) {
    return "                type=\"radio\"\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                type=\"checkbox\"\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"renderSVG")||(depth0 && lookupProperty(depth0,"renderSVG"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"radio-button",{"name":"renderSVG","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":51}}})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"renderSVG")||(depth0 && lookupProperty(depth0,"renderSVG"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"correct-checkmark",{"name":"renderSVG","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":56}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":31,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("FastFiveArticle.question", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"question-introduction\">\n            <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"introduction") || (depth0 != null ? lookupProperty(depth0,"introduction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"introduction","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":33}}}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question-container question-component\" data-question-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\n    <div class=\"question-text\">\n        <h5>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"questionText") || (depth0 != null ? lookupProperty(depth0,"questionText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionText","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":30}}}) : helper))) != null ? stack1 : "")
    + "</h5>\n    </div>\n    <div class=\"display-type-container choices-container\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FastFiveArticle.question.display-types"),depth0,{"name":"FastFiveArticle.question.display-types","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true}));
Handlebars.registerPartial("FastFiveArticle.results.display-types", Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <label \n    class=\"choice-component \n        "
    + ((stack1 = (lookupProperty(helpers,"if_eq")||(depth0 && lookupProperty(depth0,"if_eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),true,{"name":"if_eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":52}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasCorrectChoice") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "    \" data-choice-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"choiceId") || (depth0 != null ? lookupProperty(depth0,"choiceId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceId","hash":{},"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":15,"column":36}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"hasCorrectChoice") : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"poll-content\">\n            <div class=\"text-container\">\n                <div class=\"animate-text-wrapper\">\n                    <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"choiceText") || (depth0 != null ? lookupProperty(depth0,"choiceText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"choiceText","hash":{},"data":data,"loc":{"start":{"line":32,"column":23},"end":{"line":32,"column":39}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showAnsTable") : depths[1]),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"correct") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                correct\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                    wrong\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"answer-content\">\n            <span class=\"svg-icon-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"correct") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "            </span>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"renderSVG")||(depth0 && lookupProperty(depth0,"renderSVG"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ffq-correct",{"name":"renderSVG","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":50}}})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":24,"column":27}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = (lookupProperty(helpers,"renderSVG")||(depth0 && lookupProperty(depth0,"renderSVG"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ffq-wrong",{"name":"renderSVG","hash":{},"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":52}}})) != null ? stack1 : "")
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"results-container\">\n                    <div class=\"bar-container\">\n                        <div class=\"response-bar-component\"></div>\n                    </div>\n                    <div class=\"percentage-container\">\n                        <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalResponses") || (depth0 != null ? lookupProperty(depth0,"totalResponses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalResponses","hash":{},"data":data,"loc":{"start":{"line":41,"column":30},"end":{"line":41,"column":48}}}) : helper)))
    + "%</span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":47,"column":9}}})) != null ? stack1 : "")
    + "\n";
},"useData":true,"useDepths":true}));
Handlebars.registerPartial("FastFiveArticle.results", Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"question-discussion\">\n        <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"discussion") || (depth0 != null ? lookupProperty(depth0,"discussion") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"discussion","hash":{},"data":data,"loc":{"start":{"line":14,"column":11},"end":{"line":14,"column":27}}}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"choices") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":25,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"explanation") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"choice-explanation\">\n                    <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"explanation") || (depth0 != null ? lookupProperty(depth0,"explanation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"explanation","hash":{},"data":data,"loc":{"start":{"line":21,"column":23},"end":{"line":21,"column":40}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"question-introduction\">\n        <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"introduction") || (depth0 != null ? lookupProperty(depth0,"introduction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"introduction","hash":{},"data":data,"loc":{"start":{"line":30,"column":11},"end":{"line":30,"column":29}}}) : helper))) != null ? stack1 : "")
    + "</p>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question-container question-component\" data-question-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"questionId") || (depth0 != null ? lookupProperty(depth0,"questionId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionId","hash":{},"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\n    <div class=\"question-text\">\n        <h5>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"questionText") || (depth0 != null ? lookupProperty(depth0,"questionText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"questionText","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":30}}}) : helper))) != null ? stack1 : "")
    + "</h5>\n    </div>\n    <div class=\"peers-label\">\n        "
    + ((stack1 = (lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"yourPeersChose",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":37}}})) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"display-type-container choices-container\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"FastFiveArticle.results.display-types"),depth0,{"name":"FastFiveArticle.results.display-types","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discussion") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":32,"column":7}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true}));