import Medscape from 'medscape';

/* Private Variables */
let host = `api.${Medscape.getDomain()}medscape.com`;
if (window.location.href.indexOf("medscape.org") > -1) {
    host = `api.${Medscape.getDomain()}medscape.org`;
}

let QNA_URL = `https://${host}/servicegateway/v2/auth/qnaservice`;
if (document.location.hostname.indexOf('localhost') > -1) {
    //QNA_URL = "/api/qnaservice/pub";
    QNA_URL = "/servicegateway/v2/auth/qnaservice";
}

class Service {
    constructor(questionnaireId) {
        this._questionnaireId = questionnaireId;
    }
    get questionnaireId() {
        return this._questionnaireId;
    }
    getFilterData(formId, filterData) {
        let data = {
            questionnaireId: this.questionnaireId,
            formId: formId
        }
        if (filterData.professionId != null) {
            data.professionId = filterData.professionId;
        }
        if (filterData.specialtyId != null) {
            data.specialtyId = filterData.specialtyId;
        }
        let url = `${QNA_URL}/questionnaire/filter`;
        const config = {
            body: JSON.stringify(data), // must match 'Content-Type' header
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, same-origin, *omit
            headers: {
                'Accept': 'application/json',
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors' // no-cors, cors, *same-origin
        }
        return fetch(url, config).then((response) => {
            if (response.status == 200) {
                return response.json()
                    .then((data) => {
                        let _data = $.extend(true, {}, data);
                        _data.questionResponseSummaries.forEach((question) => {
                            let totalCount = question.pollSummaryChoiceResponses
                                .reduce((acc, cur) => {
                                    return acc + cur.totalCount;
                                }, 0);
                            question.totalCount = totalCount;
                            question.pollSummaryChoiceResponses.forEach((choice) => {
                                choice.totalResponses = totalCount == 0 ? 0 : Math.floor((choice.totalCount / totalCount) * 100);
                            });
                        })
                        return _data;
                    });
            } else {
                return new Promise((resolve, reject) => {
                    reject(response);
                });
            }
        })
    } //getFilterData
    getPoll(formId = 1) {
        let questionnaireId = this.questionnaireId;
        if (document.location.hostname.includes('localhost') && questionnaireId == 49192 && formId == 1) {
            return new Promise((resolve, reject) => {
                let json = require('./test-data/get-49192-1').default;
                resolve(json);
            });
        }
        let url = `${QNA_URL}/questionnaire/${questionnaireId}/form/${formId}?aggregated=true&siteId=2001`;
        const config = {
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, same-origin, *omit
            headers: {
                'Accept': 'application/json'
            },
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors' // no-cors, cors, *same-origin
        }
        return fetch(url, config).then((response) => {
                if (response.status == 200) {

                    return response.json();
                } else {
                    return new Promise((resolve, reject) => {
                        reject(response);
                    });
                }
            },
            (error) => {
                console.error("Get Poll ERRROR:\n", error);
                return error;
            })

    } // getPoll
    submitPoll(data) {
        let questionnaireId = this.questionnaireId;
        if (document.location.hostname.includes('localhost') && questionnaireId == 49192 && data.formId == 1) {
            return new Promise((resolve, reject) => {
                let json = require('./test-data/post-49192-1').default;
                resolve(json);
                //let json = require('./test-data/error').default;
                //reject(json);
            });
        }
        let postData = new PostData(data, questionnaireId);
        let END_POINT = (!data.gated && !Medscape.User.isLoggedIn()) ? 'save/anonymousresponse' : '/save/userresponse';
        let url = `${QNA_URL}/${END_POINT}?aggregated=true`
        const config = {
            body: JSON.stringify(postData), // must match 'Content-Type' header
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors' // no-cors, cors, *same-origin
        }
        return new Promise((resolve, reject) => {
            fetch(url, config).then((response) => {
                    if (response.status == 200) {
                        response.json().then(data => {
                            if ((typeof data.errorMessage) != 'undefined' && data.errorMessage != null && data.errorMessage.trim().length > 0) {
                                reject(data);
                            } else {
                                resolve(data);
                            }
                        })
                    } else {
                        console.warn("Submit Poll Response Not 200: ", response);
                        response.json().then((data) => {
                            console.log("Reject Data:", data);
                            reject(data);
                        })
                    }
                },
                (error) => {
                    console.error("Submit Poll ERRROR:\n", error);
                    reject(error);
                })
        });
    } //submitPoll
}

export class PostData {
    constructor(data, questionnaireId) {
        this.formId = data.formId;
        this.questionResponses = this.getResponseList(data.questions)
        this.questionnaireId = questionnaireId;
        this.siteId = 2001;
    }
    getResponseList(questions) {
        let responseList = [];
        questions.forEach((question) => {
        	let responses = [];
            let { matrixQuestions } = question;
            if (matrixQuestions != null && matrixQuestions.length > 0) {
                console.log("get matrix questions responses:",matrixQuestions);
                matrixQuestions.forEach(mQuestion => {
                	responses = this.getResponses(mQuestion);
                    responseList = [...responseList,...responses];
                })
            }
            else{
            	responses = this.getResponses(question);
                responseList = [...responseList,...responses];
            }
            
        });
        console.log("response list:",responseList);
        return responseList;
    }
    getResponses(question) {
    	let responses = [];
    	let { choices } = question;
        if (question.displayType == 4 || question.displayType == 5) {
            let responseText = question.responseText ? question.responseText : "";
            if (responseText.trim().length > 0) {
                let response = {
                    questionId: question.questionId,
                    responseText: responseText
                }
                responses.push(response);
            }
        } else {
            let selectedChoices = choices.filter((choice) => {
                return choice.selected;
            });
            if (selectedChoices.length > 0) {
                selectedChoices.forEach((choice) => {
                    let response = {
                        choiceId: choice.choiceId,
                        questionId: question.questionId
                    }
                    responses.push(response);
                })
            }
        }
        return responses;
    }
}
export { Service }