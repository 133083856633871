import { debounceTime } from 'rxjs/operators';

let windowScrollSubscription;
let ArticlePage = {

    disableNextSection: function() {
        $('#nextsectionlink').addClass('qna-disabled');
    },
    isPrintPage: function() {
        return window.location.pathname.match('_print') != null;
    },
    loadNextPage(curpage, totalpages, element) {
        curpage = parseInt(curpage);
        totalpages = parseInt(totalpages);
        let nextpage = curpage + 1;
        let url = '';
        // set the next index
        if (nextpage <= totalpages) {
            url = window.location.origin + window.location.pathname;
			if (typeof mscpA2A == 'boolean' && mscpA2A) {
				url = $(element).parents('.inf-art-wrap').attr('data-contenturl');
			}
            if (url.indexOf('_' + curpage) !== -1) {
                url = url.replace('_' + curpage, '_' + nextpage);
            } else {
                url += '_' + nextpage;
            }
        }
        // set the qna processor
        else {
            $('.qna-button').each(function() {
                var href = $(this).attr('href');
                if (href != null && href.indexOf('/qna/processor') !== -1) {
                    url = href;
                }
            });
        }
        if (url.trim().length > 0) {
            window.location.href = url;
        } else {
            console.warn("Failed to loadNextPage", arguments);
        }
    },
    createSponsoredPoll: function(config, containerId = 'ads-pos-910') {
		
		let targetSel = '.sponsored-poll.rightAd'
		if (typeof mscpA2A == 'boolean' && mscpA2A) {
			targetSel = '.inf-art-wrap[data-contenturl="' + currSlctCont + '"] .sponsored-poll.rightAd';
		}

        if ($(targetSel).length == 0) {

            const cssPath = '/medscape-core/qna-lib/css/sponsored-poll.min.css';
            let css = `//${Medscape.getImageServer()}${cssPath}`;
            if (document.location.hostname.includes('localhost')) {
                css = cssPath;
            }
            Medscape.loadCSS(css);
            return new Promise((resolve, reject) => {
                if (window.qna && window.qna.createSponsoredPoll) {
                    /* Create Container for the Poll */
                    let pollEl = document.createElement('div');
                    pollEl.classList.add('sponsored-poll');
                    pollEl.classList.add('qna-render')
                    pollEl.classList.add('rightAd');

                    let dataKeys = Object.keys(config.data);
                    dataKeys.forEach(key => {
                        pollEl.dataset[key] = config.data[key];
                    })

                    qna.createSponsoredPoll(pollEl).then(
                        (poll) => {
                            if (Medscape.isMobile) {
                                // Mobile placement
        
                                const qnaSubscription = qna.subscribe((polls) => {
                                    const articlePoll = polls.find(poll => {
                                        return poll.type === "ARTICLE_POLL" || poll.type=="FAST_FIVE_QUESTIONNAIRE";
                                    })
                                    if (articlePoll != null) {
                                        const settings = articlePoll.el.dataset;
                                        const curPage = parseInt(settings.curpage)
                                        const totalPages = parseInt(settings.totalpages);

                                        if (curPage == totalPages) {
                                            // On the last page of the article, poll appears before the #ads-pos-1122
                                            const targetNode = articlePoll.containerEl;
                                            const parentNode = document.getElementById('article-content');
                                            const adNode = parentNode.querySelector('#ads-pos-1122');
                                            parentNode.insertBefore(pollEl, adNode);
                                        } else {
                                            // Poll will appear on every page, above the content footer. 
                                            let targetNode = document.getElementById('column-left');
											if (typeof mscpA2A == 'boolean' && mscpA2A) {
												targetNode = document.querySelector('.inf-art-wrap[data-contenturl="' + currSlctCont + '"]');
												if (targetNode.querySelector('.qna-render[data-questionnaireid="' + settings.questionnaireid + '"]') == null && document.querySelector('.qna-render[data-questionnaireid="' + settings.questionnaireid + '"]') != null) {
													targetNode = $('.qna-render[data-questionnaireid="' + settings.questionnaireid + '"]').parents('.inf-art-wrap')[0];
												}
											}
											if (typeof targetNode !== 'undefined' && targetNode != null && targetNode.querySelector('.sponsored-poll') == null) {
												targetNode.appendChild(pollEl);
											}
                                        }

                                        if (qnaSubscription) {
                                            qnaSubscription.unsubscribe();
                                        }
                                    }
                                })
                            } else {
                                // Desktop placememnt
                                const firstAdNode = document.getElementsByClassName('rightAd')[0];
                                const parentNode = firstAdNode.parentNode;
                                // Hack code because ads are loaded asynchronously and there isn't a way to identify
                                // when the Ads are done loading.
                                // Create a placeholder element to identify where the top position of the poll is
                                // Do Not hide this element with 'display: none', otherwise jQuery won't be able to locate it's top position.
                                const placeHolder = document.createElement('div');
                                placeHolder.classList.add('sponsor-poll-top');
                                placeHolder.style.height = '1px';
                                placeHolder.style.overflow = 'hidden';

                                parentNode.insertBefore(pollEl, firstAdNode.nextSibling);
                                parentNode.insertBefore(placeHolder, pollEl);

                                setTimeout(() => {
                                    // Fix issue with Safari not able to get initial top position correctly
                                    // do NOT init another sticky handler. Any DOM manipulation should be in www article js
                                    //this.initSticky(pollEl, $(parentNode).width(), placeHolder);
                                }, 100);

                                Medscape.windowEvents.resize
                                    .pipe(debounceTime(100))
                                    .subscribe({
                                        next: event => {
                                            // do NOT init sticky on resize either!
                                            //this.initSticky(pollEl, $(parentNode).width(), placeHolder);
                                        }
                                    });
                            }
                            pollEl.classList.add('ready');
                            resolve(poll);
                        },
                        error => {
                            console.warn("Error Creating Sponsored Poll:", error);
                            reject(error);
                        });
                } else {
                    const errorMsg = "QNA API doesn't support creating sponsored poll";
                    console.warn(errorMsg)
                    reject(Error(errorMsg))
                }
            })
        }
    },
    widgets: {
        recommend: {
            exists: function() {
                let $recommendEl = $('#column-right #rel-recommend');
                let hasRecommendWidget = $recommendEl.length > 0 || $recommendEl.is(":visible");
                return hasRecommendWidget;
            }
        },
        consult: {
            exists: function() {
                let $consultEl = $('#column-right #consult-link');
                let hasConsultWidget = $consultEl.length > 0 || $consultEl.is(":visible");
                return hasConsultWidget;
            }
        }

    },
    initSticky: function(containerEl, parentWidth, placeHolderTopEl) {
        console.trace();

        // Disable sticky if: 
        //      1. The Recommend or Consult Widgets are in the right column.
        //      2. The Window's height is less than the container's height
        //      3: When the container's top position reaches the placeholder's position

        let $placeholderTopEl = $(placeHolderTopEl);
        containerEl.classList.add('sticky');
        let $containerEl = $(containerEl);
        $containerEl.css('position', '');
        $containerEl.css('top', '');
        $containerEl.css('width', '');
        let containerHeight = $containerEl.outerHeight();
        let initOffsetTop = $containerEl.offset().top;
        let $footerEl = $('#footercontents');
        let $relLinksEl = $('#rel-links-container');
        let footerOffsetTop = $footerEl.offset().top;
        let relLinksOffsetTop = $relLinksEl.length > 0 ? $relLinksEl.offset().top : Infinity;

        let offsetTops = [footerOffsetTop, relLinksOffsetTop];
        let topOffsetPos = Math.min(...offsetTops);
        let $window = $(window);

        let hasConsultWidget = this.widgets.consult.exists();
        let hasRecommendWidget = this.widgets.recommend.exists();
        let allowSticky = !hasConsultWidget && !hasRecommendWidget && ($window.height() > containerHeight);

        if (this.windowScrollSubscription) {
            this.windowScrollSubscription.unsubscribe();
        }
        if (allowSticky) {
            this.windowScrollSubscription = Medscape.windowEvents.scroll
                .subscribe({
                    next: event => {
                        let hasConsultWidget = this.widgets.consult.exists();
                        let hasRecommendWidget = this.widgets.recommend.exists();
                        let allowSticky = !hasConsultWidget && !hasRecommendWidget && ($window.height() > containerHeight);
                        if (allowSticky) {
                            checkPosition();
                        } else {
                            this.windowScrollSubscription.unsubscribe();
                            $containerEl.css('position', '');
                            $containerEl.css('top', '');
                            $containerEl.css('width', '');
                        }
                    }
                });

            checkPosition();
        }

        function checkPosition() {
            let footerOffsetTop = $footerEl.offset().top;
            let relLinksOffsetTop = $relLinksEl.length > 0 ? $relLinksEl.offset().top : Infinity;

            let offsetTops = [footerOffsetTop, relLinksOffsetTop];
            let topOffsetPos = Math.min(...offsetTops);
            let $window = $(window);
            containerHeight = $containerEl.outerHeight();
            let currentPos = $window.scrollTop();
            let hasPollData = $containerEl[0].dataset;
            let containerData = hasPollData ? Object.keys(hasPollData).filter(data => { return data === 'questionnaireid'}) : undefined;

            // place additional check for when poll is present but recommendation widget is not
            if (currentPos >= $placeholderTopEl.offset().top && (containerData && hasRecommendWidget)) {
                $containerEl.css('position', 'fixed');
                if ((currentPos + containerHeight) >= topOffsetPos) {
                    let topVal = topOffsetPos - (currentPos + containerHeight);
                    $containerEl.css('top', topVal);
                    $containerEl.css('width', parentWidth);
                } else {
                    $containerEl.css('top', 0);
                    $containerEl.css('width', parentWidth);
                }
            } else {
                $containerEl.css('position', '');
                $containerEl.css('top', '');
                $containerEl.css('width', '');
            }
        }
    }
};

export default ArticlePage;