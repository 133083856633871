import { BehaviorSubject } from 'rxjs';
import { ChoiceState } from './choice/choice-state';
const DefaultState = {
    questionId: 0,
    questionText: "",
    displayType: 1,
    poll: true,
    required: false,
    score: false,
    displayOrder: 1,
    discussion: null,
    introduction: null,
    choices: [],
    showAnsTable: true,
    showQuestion: true,
    showResult: true,
    responseText: null,
    answered: false,
    answeredCorrectly: false,
    questionTypeId: 1,
    mutuallyExclusive: false,
    matrixQuestions: [],
    totalResponses: 0,
    viewResults: false,
    specialties: [],
    professions: []
}

class QuestionState extends BehaviorSubject {
    constructor(state = Object.assign({},DefaultState)) {
        super(state);
        this._state = state;
        state.choices.sort( (c1,c2)=>{
            return c1.displayOrder - c2.displayOrder;
        })
        this._choices = state.choices.map(choiceData => {
            return new ChoiceState(choiceData);
        });
        this._matrixQuestions = state.matrixQuestions==null ? [] : state.matrixQuestions.map(question=>{
            return new QuestionState(question);
        })
    }

    get state() {
        return this._state;
    }
    get answered(){
        return this.state.answered;
    }
    set answered(flag=this.state.answered){
        if(flag!=this.state.answered){
            this.state.answered=flag;
            this.next(this.state);
        }
    }
    get questionId() {
        return this.state.questionId;
    }
    get questionText() {
        return this.state.questionText;
    }
    get displayOrder() {
        return this.state.displayOrder;
    }
    get displayType(){
        return this.state.displayType;
    }
    get introduction() {
        return this.state.introduction;
    }
    get required(){
        return this.state.required;
    }
    get choices() {
        return this._choices;
    }
    get hasCorrectChoice(){
        return this.state.hasCorrectChoice;
    }
    set hasCorrectChoice(flag = this.state.hasCorrectChoice){
        if(flag!=this.state.hasCorrectChoice){
            this.state.hasCorrectChoice = flag;
            this.next(this.state);
        }
    }
    get matrixQuestions(){
        return this._matrixQuestions;
    }
    get responseText(){
        return this.state.responseText;
    }
    set responseText(val=""){
        if(val!=this.responseText){
            this.state.responseText = val.trim();
            this.next(this.state);
        }
    }
    get specialties(){
        return this.state.specialties;
    }
    get professions(){
        return this.state.professions;
    }
    get totalResponses(){
        return this.state.totalResponses;
    }
    set totalResponses(val = this.totalResponses){
        if(val!=this.totalResponses){
            this.state.totalResponses = val;
            this.next(this.state);
        }
    }
}

export { QuestionState }