import { SubmitButtonState } from './submit-button-state';
class SubmitButtonApi{
	constructor(state = null){
		this._state = state;
	}
	get state(){
		if(this._state == null){
			this._state = new SubmitButtonState();
		}
		return this._state;
	}

	activate(){
		this.state.isActive = true;
	}
	disable(){
		this.state.isActive = false;
	}
}

export { SubmitButtonApi }