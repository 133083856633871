import Handlebars from "handlebars/runtime";export default Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"SponsoredPoll.question"),depth0,{"name":"SponsoredPoll.question","hash":{"questionsLength":((stack1 = (depths[1] != null ? lookupProperty(depths[1],"questions") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"view-results-container\">\n                    <a class=\"view-results-link view-results\" href=\"javascript:void(0)\">"
    + container.escapeExpression((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"viewResult",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":88},"end":{"line":14,"column":111}}}))
    + "</a>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-introduction\">\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"introduction") || (depth0 != null ? lookupProperty(depth0,"introduction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"introduction","hash":{},"data":data,"loc":{"start":{"line":21,"column":19},"end":{"line":21,"column":37}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sponsored-poll-questions-template form-view\" data-form-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":1,"column":71},"end":{"line":1,"column":81}}}) : helper)))
    + "\" >\n    <h4 class=\"ifi-header\">"
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"ifi",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":43}}}))
    + "</h4>\n    <h3 class=\"form-title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"formTitle") || (depth0 != null ? lookupProperty(depth0,"formTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":44}}}) : helper))) != null ? stack1 : "")
    + "</h3>\n    <form action=\"javascript:void(0)\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"questions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":17}}})) != null ? stack1 : "")
    + "        <div class=\"qna-form-actions\">\n            <button class=\"qna-button "
    + alias4(((helper = (helper = lookupProperty(helpers,"allowSubmitClass") || (depth0 != null ? lookupProperty(depth0,"allowSubmitClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"allowSubmitClass","hash":{},"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":58}}}) : helper)))
    + " submit-button-component\" type=\"submit\">\n                "
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":35}}}))
    + "\n            </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"viewResults") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"errorMsg\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "    </form>\n\n\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});