
import {SvgFactory, SvgFactoryConfig} from './lib/svg-factory';
let config = new SvgFactoryConfig();
config.iconName = "ffq-correct";
config.viewBox="0 0 25 25"
config.shape = `
<circle fill="var(--fill-circle)" cx="12.5" cy="12.5" r="12.5"></circle>
<path fill="var(--fill-path)" d="M15.9014608,12.6219512 L15.9014608,9.66592236 L18.5843877,9.66592236 L18.5843877,15.3340776 L15.9014608,15.3340776 L15.9014608,15.304878 L6.41561232,15.304878 L6.41561232,12.6219512 L15.9014608,12.6219512 Z" transform="translate(12.500000, 12.500000) scale(-1, 1) rotate(44.000000) translate(-12.500000, -12.500000)"></path>
`;

let SVG = new SvgFactory(config);

export { SVG };