import { QuestionnaireComponent } from 'qna/core/questionnaire/questionnaire-component';
import { FormView } from './form-view';
import Medscape from 'medscape';
import { RENDER_STATES } from 'qna/core/render-component';
import config from './config';
import Dictionary from 'qna/dictionary';
class ArticleQuestionnaire extends QuestionnaireComponent {
    constructor(props, containerEl) {
        super(containerEl);
        this._formView = null
        this._onSubmitSource = null;
        this._props = props;

    }

    get type() {
        return "ARTICLE_POLL";
    }
    get props() {
        let _config = Object.assign({}, config);

        let props = this._props;
        props.config = Object.assign(_config, props.config);
        let propsKeys = Object.keys(props.config);
        propsKeys.forEach(key => {
            if (props.config[key] === 'true' || props.config[key] === 'false') {
                props.config[key] = props.config[key] === 'true';
            }
        })
        return props;
    }
    get formView() {
        if (this._formView == null) {
            this._formView = new FormView();
        }
        return this._formView;
    }

    set formView(formView) {
        this._formView = formView;
    }

    get onSubmitSource() {
        return this._onSubmitSource;
    }
    set onSubmitSource(source) {
        this._onSubmitSource = source;
    }
    get $el() {
        return $(this.el);
    }
    get errorEls(){
        return [...this.el.querySelectorAll('.errorMsg')]
    }
    init() {
        super.init();
        this.el.classList.add("article-poll");
        let formId = this.el.dataset.formid;

        return this.api.getFormData(formId).then(formState => {

            this.handleFormData(formState);

            return formState;
        })
    }
    initPrint() {
        this.$el.find('.qna-question label').css({ display: 'block' });
        this.$el.find('.answer-content').css({ display: 'inline-block' });
        this.$el.find('.answer-bar').hide();
        this.$el.find('.qna-question p').css({ display: 'inline-block' });
        this.$el.find('.qna-question input').css({ display: 'inline-block' });
    }
    handleErrorMessage(errorMessage){
        this.errorEls.forEach(el=>{
            el.innerHTML = errorMessage;
        })
    }
    handleFormData(formState) {
        let formId = formState.formId;
        this.formView = new FormView(formState);
        this.formView.api.service = this.api.service;
        this.formView.isSubmitted = this.formView.api.isAnswered;
        this.formView.config = this.props.config;
        let formApi = this.formView.api;
        this.formView.feedbackMessage = this.props.config.feedbackMessage;
        let showResultsView = false;
        let render = parseInt(this.el.dataset.render);
        if (formState.formTypeId === 16 && parseInt(this.props.config.totalpages) === 1) {
            render = 1;
        } else if (window.isPrintPage) {
            render = 4;
        }

        switch (render) {
            case 1: // render mid content question and poll
                let unanswered = formApi.state.questions.find(question => {
                    return question.answered == false;
                })
                showResultsView = (formApi.expired == true || unanswered == null)
                if(showResultsView){
                    this.formView.api.setSubmitted(true);
                }
                break;
            case 2: // render question
                showResultsView = false;
                break;
            case 3: // render survey poll
            case 4:
                showResultsView = true;
                break;
        }
        if (showResultsView) {
            console.log("Show Results View");
            this.formView.api.showResultsView();
        } else {

            this.formView.api.showQuestionsView();
        }

        if (formState.gated && !Medscape.User.isLoggedIn()) {
            Medscape.ArticlePage.disableNextSection();
        }
        this.formView.init();
        this.formView.render();
        this.el.appendChild(this.formView.el);
        this.formView.renderObserver.subscribe(state => {
            if (state.type == RENDER_STATES.BEFORE) {
                this.handleFormViewBeforeRender();
            } else if (state.type == RENDER_STATES.AFTER) {
                this.handleFormViewAfterRender();
            }
        })
        if (Medscape.ArticlePage.isPrintPage()) {
            this.initPrint();
        }
    }
    handleFormViewBeforeRender() {
        if (this.onSubmitSource != null) {
            this.onSubmitSource.unsubscribe();
            this.onSubmitSource = null;
        }
    }
    handleFormViewAfterRender() {
        if (this.formView.onSubmit != null) {
            this.onSubmitSource = this.formView.onSubmit.subscribe(data => {
                let { formState } = data;
                if (this.formView.api.isValidForm) {
                    const { curpage, totalpages } = this.el.dataset;
                    let showResultsView = (this.el.dataset.render==1 || curpage==totalpages);
                    this.api.submitForm(formState).then(data => {
                            if(showResultsView){
                                this.formView.api.setSubmitted(true);
                                this.formView.api.showResultsView();
                            }
                            else{
                                Medscape.ArticlePage.loadNextPage(curpage, totalpages);
                            }
                        },
                        error => {
                            console.error("Submit Poll Error:", error);
                            this.handleErrorMessage(Dictionary.systemError[Medscape.getLocale()]);
                        });
                } else {
                    this.handleErrorMessage(Dictionary.answerAllError[Medscape.getLocale()])
                }
            })
        }

        if (this.formView.state.showResultsView) {
            this.el.classList.remove('show-questions');
            this.el.classList.add('show-polling');
        } else {
            this.el.classList.add('show-questions');
            this.el.classList.remove('show-polling');
        }
    }

}

export { ArticleQuestionnaire }
