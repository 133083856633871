export default {
    select: {
        'us': 'Select Answer',
        'es': 'Seleccione una respuesta',
        'pt': 'Escolha uma resposta',
        'de': 'Antwort auswählen',
        'fr': 'Sélectionnez une réponse'
    },
    systemError: {
        'us': 'We\'re sorry, our system has encountered an error. Please try again.',
        'es': 'Lo sentimos, se ha producido un error en el sistema. Por favor, inténtelo de nuevo.',
        'pt': 'Desculpe,houve um erro do sistema. Por favor tente novamente.',
        'de': 'Leider kam es zu einem Fehler im System. Bitte versuchen Sie es erneut.',
        'fr': 'Nous sommes désolés, une erreur s’est produite. Veuillez réessayer.'
    },
    answerAllError: {
        'us': 'Please answer all questions',
        'es': 'Por favor conteste todas las preguntas',
        'pt': 'Por favor responda a todas as perguntas',
        'de': 'Bitte beantworten Sie alle Fragen',
        'fr': 'Répondez à toutes les questions s’il vous plait'
    },
    question: {
        'us':  'Question',
        'es':  'Pregunta',
        'pt':  'Pergunta',
        'de':  'Frage',
        'fr':  'Question',
    },
    of: {
        'us':  'of',
        'es':  'de',
        'pt':  'de',
        'de':  'von',
        'fr':  'sur',
    },
    viewResult: {
        'us':  'View Results',
        'es':  'Ver las respuestas',
        'pt':  'Ver os resultados',
        'de':  'Zu den Ergebnissen',
        'fr':  'Voir les résultats',
    },
    peerChose: {
        'us':  'Your Peers Chose',
        'es':  'Sus colegas eligieron',
        'pt':  'Seus pares escolheram',
        'de':  'Die Auswahl Ihrer Kollegen',
        'fr':  'Vos collègues ont répondu',
    },
    colleaguesResponded: {
        'us':  'Your colleagues responded',
        'es':  'Sus colegas respondieron',
        'pt':  'Seus colegas responderam',
        'de':  'So haben Ihre Kollegen geantwortet',
        'fr':  'Vos collègues ont répondu',
    },
    filterBySpecialty: {
        'us':  'Filter Results by Specialty',
        'es':  'Filtrar los resultados por especialidad',
        'pt':  'Filtre os resultados por especialidade',
        'de':  'Filter der Ergebnisse nach Fachrichtungen',
        'fr':  'Filtrer les résultats par spécialité',
    },
    filterByProfession: {
        'us':  'Filter Results by Profession',
        'es':  'Filtrar los resultados por profesión',
        'pt':  'Filtre os resultados por profissão',
        'de':  'Filter der Ergebnisse nach Berufsgruppen',
        'fr':  'Filtrer les résultats par profession',
    },
    submit: {
    	'us':  'Submit',
        'es':  'Enviar',
        'pt':  'Enviar',
        'de':  'Senden',
        'fr':  'Valider',
    },
    submitAndProceed: {
        'us': 'Submit & Proceed',
        'es': 'Enviar y continuar',
        'pt': 'Enviar e continuar',
        'de': 'Senden und weiter',
        'fr': 'Enregistrer et continuer'
    },
    save: {
        'us':  'Save and Proceed',
        'es':  'Guardar y Continuar',
        'pt':  'Salvar e continuar',
        'de':  'Speichern und fortfahren',
        'fr':  'Enregistrer et Continuer',
    },
    totalResponses: {
        'us':  'Total Responses',
        'es':  'Todas las respuestas',
        'pt':  'Todas as respostas',
        'de':  'Alle Antworten',
        'fr':  'Ensemble des réponses',
    },
    returnToQuestion: {
        'us':  'Return to Question',
        'es':  'Volver a la pregunta',
        'pt':  'Voltar à pergunta',
        'de':  'Zurück zur Frage',
        'fr':  'Retourner aux questions',
    },
    expired: {
        'us':  'This polling question has expired',
        'es':  'Esta encuesta ya ha finalizado',
        'pt':  'Esta enquete expirou',
        'de':  'Diese Umfrage ist beendet',
        'fr':  'Cette question du sondage a expiré',
    },
    profession: {
        'us': {
            0: "All",
            10: "Medical Doctor/Physician",
            20: "Dentist/Oral Health Professional",
            13: "Health Business/Administration",
            19: "Media/Press",
            16: "Medical Student",
            12: "Nurse/Advanced Practice Nurse",
            22: "Optometrist",
            15: "Other Healthcare Provider",
            8: "Pharmacist",
            11: "Physician Assistant",
            23: "Psychologist",
            17: "Consumer/Other",
        },
        'es': {
            0: "Todas",
            10: "Médico",
            20: "Dentista /Cirujano Dentista",
            13: "Empresa de salud / Administración",
            19: "Periodista",
            16: "Estudiante de  Medicina/Interna",
            12: "Enfermera",
            22: "Optometrista",
            15: "Otra profesión de la salud",
            8: "Farmacéutico",
            11: "Asistente Médico",
            23: "Psicólogo",
            17: "Otra",
        },
        'pt': {
            0: "Todas",
            10: "Médico(a)",
            20: "Dentista",
            13: "Empresa do setor de saúde / Administração",
            19: "Jornalista",
            16: "Estudante de medicina",
            12: "Enfermeiro(a)",
            22: "Optometrista",
            15: "Outro profissional de   saúde",
            8: "Farmacêutico(a)",
            11: "Assistente médico",
            23: "Psicólogo(a)",
            17: "Outras profissões",
        },
        'de': {
            0: "Alle",
            10: "Arzt",
            20: "Zahnarzt/zahnmedizinischer Fachangestellter",
            13: "Mitarbeiter in medizinischer Institution oder Pharmaindustrie",
            19: "Medizinjournalist/in",
            16: "Student",
            12: "Krankenschwester/Krankenpfleger",
            22: "Optiker",
            15: "Sonstige Gesundheitsdienstleister",
            8: "Apotheker",
            11: "Medizinischer Fachangestellter",
            23: "Psychologe",
            17: "Sonstige",
        },
        'fr': {
            0: "Tout",
            10: "Médecin",
            20: "Dentiste / Chirurgien Dentiste",
            13: "Entreprise de santé/Administration",
            19: "Journaliste",
            16: "Étudiant en médecine/Interne",
            12: "Infirmier",
            22: "Optométriste",
            15: "Autre professionel de la santé",
            8: "Pharmacien",
            11: "Assistant médical",
            23: "Psychologue",
            17: "Autre"
        }
    },
    specialty: {
        'us': {
            0: "All",
            1: "Allergy and Clinical Immunology",
            2: "Anesthesiology",
            3: "Cardiology",
            4: "Critical Care",
            5: "Dentistry",
            6: "Dermatology",
            7: "Diabetes & Endocrinology",
            8: "Emergency Medicine",
            9: "Family Medicine",
            10: "Gastroenterology",
            11: "General Surgery",
            13: "HIV/AIDS",
            12: "Hematology/Oncology",
            14: "Infectious Diseases",
            15: "Internal Medicine",
            16: "Med Students",
            17: "Nephrology",
            18: "Neurology",
            19: "Ob/Gyn & Women's Health",
            20: "Ophthalmology",
            21: "Orthopedics",
            22: "Other Clinical",
            23: "Otolaryngology",
            24: "Pain Management",
            25: "Pain Medicine",
            26: "Palliative Medicine",
            27: "Pathology",
            28: "Pediatrics",
            29: "Pharmacists",
            30: "Plastic Surgery",
            31: "Psychiatry",
            32: "Public Health",
            33: "Pulmonary Medicine",
            34: "Radiology",
            35: "Rheumatology",
            36: "Transplantation",
            37: "Urology",
            38: "Vascular Medicine",
            39: "Hospital Medicine"
        },
        'es': {
            0: "Todas",
            1: "Alergia e Inmunología Clínica",
            2: "Anestesiología",
            3: "Cardiología",
            4: "Cuidado crítico",
            5: "Odontología",
            6: "Dermatología",
            7: "Diabetes y Endocrinología",
            8: "Medicina de emergencia",
            9: "Medicina familiar",
            10: "Gastroenterología",
            11: "Cirugía general",
            12: "Hematología / Oncología",
            13: "VIH / SIDA",
            14: "Enfermedades infecciosas",
            15: "Medicina interna",
            16: "Estudiantes de Med",
            17: "Nefrología",
            18: "Neurología",
            19: "Ginecología/ Obstetricia",
            20: "Oftalmología",
            21: "Ortopedia",
            22: "Otro Clínico",
            23: "Otorrinolaringología",
            24: "Manejo del dolor",
            25: "Medicina del dolor",
            26: "Medicina paliativa",
            27: "Patología",
            28: "Pediatría",
            29: "Farmacéuticos",
            30: "Cirugía plástica",
            31: "Psiquiatría",
            32: "Salud pública",
            33: "Medicina pulmonar",
            34: "Radiología",
            35: "Reumatología",
            36: "Trasplante",
            37: "Urología",
            38: "Medicina vascular",

        },
        'pt': {
            0: "Todas",
            1: "Alergia e imunologia clínica",
            2: "Anestesiologia",
            3: "Cardiologia",
            4: "Cuidados Críticos",
            5: "Odontologia",
            6: "Dermatologia",
            7: "Diabetes e Endocrinologia",
            8: "Medicina de emergência",
            9: "Medicina familiar",
            10: "Gastroenterologia",
            11: "Cirurgia Geral",
            12: "Hematologia / Oncologia",
            13: "HIV / AIDS",
            14: "Doenças Infecciosas",
            15: "Medicina interna",
            16: "Estudantes Med",
            17: "Nefrologia",
            18: "Neurologia",
            19: "Ginecologia e Obstetrícia",
            20: "Oftalmologia",
            21: "Ortopedia",
            22: "Outro Clínico",
            23: "Otorrinolaringologia",
            24: "Gestão da dor",
            25: "Medicina da dor",
            26: "Medicina paliativa",
            27: "Patologia",
            28: "Pediatria",
            29: "Farmacêuticos",
            30: "Cirurgia Plástica",
            31: "Psiquiatria",
            32: "Saúde Pública",
            33: "Medicina pulmonar",
            34: "Radiologia",
            35: "Reumatologia",
            36: "Transplante",
            37: "Urologia",
            38: "Medicina Vascular",

        },
        'de': {
            0: "Alle",
            1: "Allergie und klinische Immunologie",
            2: "Anästhesiologie",
            3: "Kardiologie",
            4: "Kritische Pflege",
            5: "Zahnheilkunde",
            6: "Dermatologie",
            7: "Diabetes & Endokrinologie",
            8: "Notfallmedizin",
            9: "Familienmedizin",
            10: "Gastroenterologie",
            11: "Allgemeine Chirurgie",
            12: "Hämatologie / Onkologie",
            13: "HIV / AIDS",
            14: "Infektionskrankheiten",
            15: "Innere Medizin",
            16: "Med Studenten",
            17: "Nephrologie",
            18: "Neurologie",
            19: "Gynäkologie / Geburtshilfe",
            20: "Augenheilkunde",
            21: "Orthopädie",
            22: "Andere klinische",
            23: "Otolaryngologie",
            24: "Schmerztherapie",
            25: "Schmerzmedizin",
            26: "Palliativmedizin",
            27: "Pathologie",
            28: "Pädiatrie",
            29: "Apotheker",
            30: "Plastische Chirurgie",
            31: "Psychiatrie",
            32: "Öffentliche Gesundheit",
            33: "Lungenmedizin",
            34: "Radiologie",
            35: "Rheumatologie",
            36: "Transplantation",
            37: "Urologie",
            38: "Gefäßmedizin",
        },
        "fr": {
            0: "Tout",
            1: "Allergie et immunologie clinique",
            2: "Anesthésiologie",
            3: "Cardiologie",
            4: "Soins critiques",
            5: "Dentisterie",
            6: "Dermatologie",
            7: "Diabète et Endocrinologie",
            8: "Médecine d'urgence",
            9: "Médecine familiale",
            10: "Gastroentérologie",
            11: "Chirurgie générale",
            12: "Hématologie / Oncologie",
            13: "VIH / SIDA",
            14: "Maladies infectieuses",
            15: "Médecine interne",
            16: "Etudiants Med",
            17: "Néphrologie",
            18: "Neurologie",
            19: "Gynéco/Obstétrique",
            20: "Ophtalmologie",
            21: "Orthopédie",
            22: "Autre clinique",
            23: "Otolaryngologie",
            24: "Gestion de la douleur",
            25: "Médecine de la douleur",
            26: "Médecine palliative",
            27: "Pathologie",
            28: "Pédiatrie",
            29: "Pharmaciens",
            30: "Chirurgie plastique",
            31: "Psychiatrie",
            32: "Santé publique",
            33: "Médecine pulmonaire",
            34: "Radiologie",
            35: "Rhumatologie",
            36: "Transplantation",
            37: "Urologie",
            38: "Médecine vasculaire",
        }
    },    
    "ifi":{
    	'us':  'Information from Industry',
        'es':  'Información de la industria',
        'pt':  'Informação da Indústria',
        'de':  'Informationen der Industrie',
        'fr':  'Information de l\'industrie',
    },
    "feedbackMessage":{
    	'us':  'Thank You for Participating.',
    	'es':  'Gracias por participar.',
        'pt':  'Obrigado por participar.',
        'de':  'Vielen Dank für Ihre Teilnahme.',
        'fr':  'Merci pour votre participation.',
    },
    "answer":{
    	'us':  'Answer',
    	'es':  'Respuesta',
        'pt':  'Resposta',
        'de':  'Antwort',
        'fr':  'Réponse',
    },
    "yourPeersChose":{
    	'us':  'Your peers chose:',
    	'es':  'Sus colegas eligieron:',
        'pt':  'Seus colegas responderam:',
        'de':  'So antworteten Ihre Kollegen:',
        'fr':  'Vos confrères ont répondu:',
    }
    
}

