import { SubmitButtonApi } from './submit-button-api';

class SubmitButtonComponent{
	constructor(el){
		this._el = el;
		this.api = null
		this._subscription = null;
	}
	get api(){
		if(this._api==null){
			this._api = new SubmitButtonApi();
		}
		return this._api;
	}
	set api(api=null){
		this._api = api;
	}
	get state(){
		return this.api.state;
	}
	get el(){
		return this._el;
	}
	get subscription(){
		return this._subscription;
	}
	set subscription(subscription=null){
		this._subscription = subscription;
	}
	destroy(){
		if(this.subscription){
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}
	init(){
		this.subscription = this.state.subscribe( buttonState=>{
			this.update(buttonState.isActive);
		})
	}
	update(isActive=this.state.isActive){
		this.el.disabled = !isActive;
	}
}

export { SubmitButtonComponent }