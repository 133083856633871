import { BehaviorSubject } from 'rxjs';
import { QuestionState } from './question/question-state';
const DefaultState = {
    "formId": 1,
    "formTitle": null,
    "formTypeId": 1,
    "formType": "DEFAULT",
    "displayOrder": 1,
    "introduction": null,
    "questions": [],
    "isActive": 1,
    "gated": true,
    "expirationDate": null,
    "viewResults": false,
    "showResultsView": false,
    "errorMessage": "",
    "isSubmitted": false
}

class FormState extends BehaviorSubject {
    constructor(state = Object.assign({},DefaultState)) {
        super(state);
        this._state = Object.assign(Object.assign({},DefaultState),state);
        this._state.questions.sort( (q1,q2)=>{
            return q1.displayOrder - q2.displayOrder;
        })
        this._questions = this._state.questions.map(questionData => {
            return new QuestionState(questionData);
        });
    }

    get state() {
        return this._state;
    }
    get formId() {
        return this.state.formId;
    }
    get formTypeId(){
        return this.state.formTypeId;
    }
    get formTitle() {
        return this.state.formTitle;
    }
    get displayOrder() {
        return this.state.displayOrder;
    }
    get introduction() {
        return this.state.introduction;
    }
    get questions() {
        return this._questions;
    }
    get isSubmitted(){
        return this.state.isSubmitted;
    }
    set isSubmitted(flag=this.isSubmitted){
        if(flag!=this.isSubmitted){
            this.state.isSubmitted = flag;
            this.next(this.state);
        }
    }
    get showResultsView(){
        return this.state.showResultsView;
    }
    set showResultsView(flag=this.showResultsView){
        if(flag!=this.showResultsView){
            this.state.showResultsView = flag;
            this.next(this.state);
        }
    }
    get errorMessage(){
        return this.state.errorMessage;
    }
    set errorMessage(msg=null){
        if(msg!=this.errorMessage){
            this.state.errorMessage = msg;
            this.next(this.state);
        }
    }
    get viewResults(){
        return this.state.viewResults;
    }
    set viewResults(flag = this.viewResults){
        if(flag!=this.viewResults){
            this.state.viewResults = flag;
            this.next(this.state);
        }
    }
    toJSON(){
        let json = JSON.stringify(this.state);
        return JSON.parse(json);
    }
}

export { FormState }