import { FiltersState } from './filters-state';
class FiltersApi{
	constructor(state = null){
		this._state = state;
	}
	get state(){
		if(this._state == null){
			this._state = new FiltersState();
		}
		return this._state;
	}

	setProfessionId(id){
		this.state.professionId = id;
	}
	setSpecialtyId(id){
		this.state.specialtyId = id;
	}
}

export { FiltersApi }