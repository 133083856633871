import { BehaviorSubject } from 'rxjs';
const DefaultState = {
    isActive: false
}
class SubmitButtonState extends BehaviorSubject {
    constructor(state = DefaultState) {
        super(Object.assign(Object.assign({}, DefaultState),state));
        this._state = Object.assign(Object.assign({}, DefaultState),state);
    }
    get state() {
        return this._state;
    }
    get isActive() {
        return this.state.isActive;
    }
    set isActive(flag = this.state.isActgive) {
        if (flag != this.isActive) {
            this.state.isActive = flag;
            this.next(this.state);
        }
    }

}

export { SubmitButtonState }
