import { FormState } from './form-state';
class FormApi{
	constructor(state = null){
		this._state = state;
		this._service = null;

	}
	get state(){
		if(this._state==null){
			this._state = new FormState();
		}
		return this._state;
	}
	get isAnswered(){
		let questions = this.state.questions;
		let answered = questions.find(question=>{
			return question.answered;
		})
		return answered!=null;
	}
	get isValidForm(){
		let questions = this.state.questions;
		let isValidForm = true;
		let isAnswered = false;
		questions.forEach(question=>{
			if(!question.answered && question.required){
				isValidForm = false;
			}
			if(question.answered){
				isAnswered = true;
			}
		})
		return isAnswered && isValidForm;
	}
	get expired(){
		let isExpired = false;
        if (this.stateexpirationDate !== null) {
            let expirationDate = new Date(this.state.expirationDate);
            if (expirationDate.getTime() < Date.now()) {
                isExpired = true;
            }
        }
        return isExpired;
	}
	get service(){
		return this._service;
	}
	set service(service){
		this._service = service;
	}
	getQuestionState(questionId){
		return this.state.questions.find(question=>{
			return question.questionId == questionId;
		})
	}

	showResultsView(){
		this.state.showResultsView = true;
	}
	showQuestionsView(){
		this.state.showResultsView = false;
	}
	setError(error=null){
		if(error==null){
			this.state.errorMessage = null;
		}
		else if(typeof error === "string"){
			this.state.errorMessage = error;
		}
		else if(error.statusText){
			this.state.errorMessage = error.statusText;
		}
		else if(error.errorMessage){
			this.state.errorMessage = error.errorMessage;
		}
	}
	setSubmitted(flag=false){
		this.state.isSubmitted = flag;
	}
	submitForm(formState){
		let formData = formState.toJSON();
		return this.service.submitPoll(formData);
	}
}

export { FormApi }