import { QuestionComponent } from 'qna/core/questionnaire/form/question/question-component';
import { ChoiceComponent } from 'qna/core/questionnaire/form/question/choice/choice-component';
const TRANSITION_END_EVENTS = "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend";
/* Override default Component to handle animations */
class QuestionsTemplateQuestionView extends QuestionComponent{
	constructor(el,questionState){
		super(el,questionState);

	}

	get ChoiceView(){
		return ChoiceView;
	}
}


class ChoiceView extends ChoiceComponent{
	constructor(el,state){
		super(el,state);
	}
	handleBeforeRender(){
		super.handleBeforeRender();
		this.el.classList.remove('ready');
	}
	handleAfterRender(){
		super.handleAfterRender();

        if(this.ResponseBar!=null){
        	this.ResponseBar.api.width("0%");
        	let transitionEndSubsriber = this.ResponseBar.onTransitionEnd.subscribe(e=>{
        		this.ResponseBar.api.width(`${this.state.totalResponses}%`);
        	})
        	this.subscriptions.push(transitionEndSubsriber);
        }
        setTimeout(()=>{
        	this.el.classList.add('ready');
        },500)
	}
}

export { QuestionsTemplateQuestionView }