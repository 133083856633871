import { BehaviorSubject } from 'rxjs';
const DefaultState = {
    professionId: null,
    specialtyId: null
}
class FiltersState extends BehaviorSubject {
    constructor(state = DefaultState) {
        super(Object.assign(Object.assign({}, DefaultState),state));
        this._state = Object.assign(Object.assign({}, DefaultState),state);
    }
    get state() {
        return this._state;
    }
    get professionId(){
        return this.state.professionId;
    }
    set professionId(id=null){
        if(id!=null && (id=="0" || id.trim().length==0)){
            id = null;
        }
        if(id!=this.professionId){
            this.state.professionId = id;
            this.next(this.state);
        }
    }
    get specialtyId(){
        return this.state.specialtyId;
    }
    set specialtyId(id=null){
        if(id!=null && (id=="0" || id.trim().length==0)){
            id = null;
        }
        if(id!=this.specialtyId){
            this.state.specialtyId = id;
            this.next(this.state);
        }
    }

}

export { FiltersState }
