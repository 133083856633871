import { BehaviorSubject } from 'rxjs';
import { FormState } from './form/form-state';
const DefaultState = {
  "questionnaireId": null,
  "questionnaireTypeId": 1,
  "passScore": 0,
  "questionnaireTitle": null,
  "passMessage": null,
  "failMessage": null,
  "gated": true,
  "expirationDate": null,
  "questionForms": [],
  "activityInfo": null,
  "testLessActivity": true
}

class QuestionnaireState extends BehaviorSubject{
  constructor(state=Object.assign({},DefaultState)){
    super(state)
    this._state = state;
    this._questionForms = null
  }

  get state(){
    return this._state;
  }
  get questionnaireId(){
    return this.state.questionnaireId;
  }
  set questionnaireId(val=null){
    if(val!=this.state.questionnaireId){
      this.state.questionnaireId = val;
      this.next(this.state);
    }
  }
  get questionForms(){
    if(this._questionForms==null){
      this._questionForms = this.state.questionForms.map(formData=>{
        return new FormState(formData);
      });
    }
    return this._questionForms;
  }

}

export { QuestionnaireState }