import Handlebars from "handlebars/runtime";export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"gated-question-login\">"
    + container.escapeExpression((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"expired",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":58}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gated") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"gated-question-login\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gatedLogin") || (depth0 != null ? lookupProperty(depth0,"gatedLogin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gatedLogin","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":58}}}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <form id=\"qna-form-Q-"
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":8,"column":29},"end":{"line":8,"column":39}}}) : helper)))
    + "\" action=\"javascript:void(0);\">\n            <input type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":9,"column":40},"end":{"line":9,"column":50}}}) : helper)))
    + "\" name=\"formid\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"introduction") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"questions") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":16,"column":21}}})) != null ? stack1 : "")
    + "            <div class=\"qna-form-actions\">\n                <button class=\"qna-button submit-button-component \" type=\"submit\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"render") : depth0),"==","1",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":23,"column":31}}})) != null ? stack1 : "")
    + "                </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"viewResults") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":27,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </form>\n\n        <div class=\"errorMsg\"></div>\n        <div class=\"errorMsg-text\">\n            <span class=\"errorMsg-system\">"
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"systemError",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":42},"end":{"line":33,"column":66}}}))
    + "</span>\n            <span class=\"errorMsg-answerAll\">"
    + alias4((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"answerAllError",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":45},"end":{"line":34,"column":72}}}))
    + "</span>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"questiontext12\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"introduction") || (depth0 != null ? lookupProperty(depth0,"introduction") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"introduction","hash":{},"data":data,"loc":{"start":{"line":12,"column":44},"end":{"line":12,"column":62}}}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"Article.question"),depth0,{"name":"Article.question","hash":{"questionsLength":((stack1 = (depths[1] != null ? lookupProperty(depths[1],"questions") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1)},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":43}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":22,"column":41}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a class=\"qna-view-button view-results\" href=\"\">"
    + container.escapeExpression((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"viewResult",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":68},"end":{"line":26,"column":91}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"article-questions-template form-view\" data-form-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"formId") || (depth0 != null ? lookupProperty(depth0,"formId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"formId","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":74}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"expired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":37,"column":7}}})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});