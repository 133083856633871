import SponsoredPoll from './questionnaire';
export default SponsoredPoll;
/*
export default class SponsoredPoll extends QuestionnaireComponent{
	constructor(props,containerEl){
        super(props,containerEl);
        this.type="SPONSORED_POLL";
        this._initSubscription = null;
        this.initSubscription();
        this.stateSubscriptions = this.state.subscribe( (state)=>{
            if(state.isReady){
                this.render(state);
                if(this._initSubscription){
                    this._initSubscription.unsubscribe();
                }
            }
        })

	}
    initSubscription(){
        this._initSubscription = this.formDataModel.subscribe( 
            (data)=>{
                if(data!=null && !this.state.getState().isReady && data.questions.length>0){
                    const unanswered = data.questions.find( (question)=>{
                        return question.answered == false;
                    })
                    this.state.setState({
                        isReady: true,
                        showResults: unanswered==null || this.formDataModel.isExpired()
                        //showResults: false
                    })
                    
                }
            },
            error=>{
                console.error("Init Sponsoer Poll Error:",error);
            })
    }
    initViews(){
        const questionsProps = {
            onShowResults: super.showResultsView.bind(this),
            onSubmit: super.submitPoll.bind(this),
            getPostData: this.getPostData.bind(this)
        }
        const resultsProps = {
            onShowQuestions: super.showQuestionsView.bind(this),
            feedbackMessage: this.containerEl.dataset.feedbackMessage
        }

        const isExpired = this.formDataModel.isExpired();
        this.resultsTemplateView = new ResultsTemplate(resultsProps,this.formDataModel);
        this.questionsTemplateView = new QuestionsTemplate(questionsProps,this.formDataModel);
        //this.resultsTemplateView.render();
        //this.questionsTemplateView.render();

        this.$containerEl.show();
    }
    render(state=this.state.getState()){
        if(state.isReady==false){
            return;
        }
        if(this.view!=null){
            this.view.el.remove();
        }
        else{
            this.initViews();
            this.containerEl.appendChild(this.el);
        }
        if(state.showResults){
            this.$containerEl.removeClass('show-questions').addClass('show-polling');
            this.view = this.resultsTemplateView;
        }else{
            this.$containerEl.removeClass('show-polling').addClass('show-questions');
            this.view = this.questionsTemplateView;
        }
        //this.view.beforeRender();
        this.view.render();
        this.el.appendChild(this.view.el);
        this.view.afterRender();
    }

}
*/
