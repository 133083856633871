import { FormComponent } from 'qna/core/questionnaire/form/form-component';
import { default as QuestionTemplate } from 'templates/fast-five-article.questions';
import { default as ResultTemplate } from 'templates/fast-five-article.results';
import { ResultsQuestionView } from './results-question-view';

class FormView extends FormComponent{
	constructor(formState){
		super(formState);
	}

	get template(){
		if(this.state.showResultsView==true){
			return ResultTemplate;
		}
		else{
			return QuestionTemplate;
		}
	}
	get QuestionView(){
		if(this.state.showResultsView==true){
			return ResultsQuestionView;
		}
		else{
			return super.QuestionView;
		}
	}

}
export { FormView }